<template>
  <div>
    <div>
      <button @click="monthly" v-bind:class="{ chosen: this.plan === 'gpxw' }">
        <div class="active"></div>
        <div>
          Monthly
          <br />Billing
        </div>
        <div class="price">10€</div>
      </button>
      <button @click="anually" v-bind:class="{ chosen: this.plan === '8qrr' }">
        <div class="active"></div>
        <div>
          Annual
          <br />Billing
        </div>
        <div class="price">100€</div>
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapState({
      plan: (state) => state.userData.subscriptionPlan,
    }),
  },
  methods: {
    monthly() {
      this.$store.commit("updateUserDataProp", {
        prop: "subscriptionPlan",
        value: "gpxw",
      });
      this.$emit("next");
    },
    anually() {
      this.$store.commit("updateUserDataProp", {
        prop: "subscriptionPlan",
        value: "8qrr",
      });
      this.$emit("next");
    },
  },
};
</script>

<style scoped lang="scss">
h3 {
  font-size: 60px;
  margin: 0;
}

div {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-around;
  align-items: center;
}

button {
  background-color: transparent;
  border: 1px solid #0a2540;
  width: 40%;
  height: 60%;
  font-weight: bold;
  font-size: 36px;
  line-height: 42px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #0a2540;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-top: 50px;
  padding-bottom: 50px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  &:hover {
    cursor: pointer;
    .active {
      width: 200%;
      height: 200%;
    }
  }
  div {
    z-index: 5;
  }
  .price {
    font-weight: bold;
    font-size: 56px;
    line-height: 24px;
    letter-spacing: 1px;
    color: #9866fe;
  }
  .active {
    width: 0;
    background-color: #a2f6ac;
    height: 0;
    position: absolute;
    right: -50%;
    top: -50%;
    transition: width 0.4s ease-in-out, height 0.4s ease-in-out;
    border-radius: 50%;
    border-top-right-radius: 0;
  }
}

button.chosen .active {
  width: 200%;
  height: 200%;
}

@media only screen and (max-width: 1080px) {
  button {
    font-size: 20px;
    height: 40%;
    width: 90%;
    line-height: 1.5em;
    .price {
      font-size: 25px;
      margin-top: 20px;
    }
  }
  div {
    flex-direction: column;
  }
}
</style>
