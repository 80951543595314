<template>
  <div id="form-wrap">
    <section>
      <AccountInput
        type="text"
        name="First Name"
        :value="this.firstname"
        @input="updateFirstName"
        placeholder="type your name..."
        tabindex="1"
        img="/img/icons/name.svg"
        editable
        required
      />
      <AccountInput
        type="text"
        name="Last Name"
        :value="this.lastname"
        @input="updateLastName"
        placeholder="type your name..."
        tabindex="1"
        img="/img/icons/name.svg"
        editable
        required
      />
      <AccountInput
        type="text"
        name="Address"
        :value="this.street"
        @input="updateStreet"
        placeholder="type your address..."
        tabindex="2"
        img="/img/icons/place.svg"
        editable
        required
      />
      <AccountInput
        type="text"
        name="City"
        :value="this.city"
        @input="updateCity"
        placeholder="type your city..."
        tabindex="3"
        img="/img/icons/city.svg"
        editable
        required
      />
    </section>
    <section>
      <AccountInput
        type="text"
        name="ZIP Code"
        :value="this.zipcode"
        @input="updateZipcode"
        placeholder="type your zip code..."
        tabindex="4"
        img="/img/icons/zip.svg"
        editable
        required
      />
      <AccountDropDown
        name="Country"
        :value="this.country"
        @input="updateCountry"
        tabindex="5"
        img="/img/icons/world.svg"
        :options="countries"
        required
        placeholder="Choose a country"
      />
      <AccountSwitch
        type="text"
        name="Account Type"
        :value="this.isCompany"
        @input="updateIsCompany"
        option1="Private"
        option2="Company"
        placeholder="***********"
        tabindex="7"
        img="/img/icons/arrows.svg"
        required
      />
      <AccountInput
        v-show="this.isCompany"
        type="text"
        name="Company Name"
        :value="this.companyName"
        @input="updateCompanyName"
        placeholder="type company name..."
        tabindex="11"
        img="/img/icons/city.svg"
        editable
        required
      />
    </section>
    <section id="code-section">
      <AccountInput
        type="text"
        name="Discount Code"
        v-model="discount"
        placeholder="Code..."
        tabindex="15"
        img="/img/icons/dollar.svg"
        :icon="discountInputIcon"
        editable
      />
      <div v-if="codeValid" id="subscription-rebate">
        Your subscription will be {{ rebate.amount }}% off for
        {{ rebate.time }}!
      </div>
    </section>
  </div>
</template>

<script>
import AccountInput from "@/components/AccountInput.vue";
import AccountSwitch from "@/components/AccountSwitch.vue";
import AccountDropDown from "@/components/AccountDropDown.vue";
import { mapState } from "vuex";
import { countries } from "@/countries.js";
export default {
  components: { AccountInput, AccountSwitch, AccountDropDown },
  computed: {
    ...mapState({
      firstname: (state) => state.userData.firstname,
      lastname: (state) => state.userData.lastname,
      email: (state) => state.userData.email,
      street: (state) => state.userData.street,
      zipcode: (state) => state.userData.zipcode,
      city: (state) => state.userData.city,
      country: (state) => state.userData.country,
      isCompany: (state) => state.userData.isCompany,
      companyName: (state) => state.userData.companyName,
      subscriptionPlan: (state) => state.userData.subscriptionPlan,
    }),
    filledOut() {
      return (
        this.firstname.length > 0 &&
        this.lastname.length > 0 &&
        this.street.length > 0 &&
        this.city.length > 0 &&
        this.zipcode.length > 0 &&
        this.country.length > 0 &&
        (!this.isCompany || this.companyName.length > 0)
      );
    },
    codeValid() {
      if (this.discounts)
        return this.discounts.codes[this.discount] !== undefined;
      return false;
    },
    discountInputIcon() {
      if (this.codeValid) return "/img/icons/checkmark.svg";
      if (this.discount) return "/img/icons/redX.svg";
      return null;
    },
    rebate() {
      let code = this.discounts.codes[this.discount];
      let rebate = this.discounts.discounts[this.subscriptionPlan][code];
      let type = this.subscriptionPlan === "8qrr" ? "year" : "months";
      let time = `${rebate.numberOfBillingCycles} ${type}`;
      let normalAmount = type === "year" ? 100 : 10;
      let amount = Math.round(
        (Number.parseFloat(rebate.amount) / normalAmount) * 100
      );
      return { time, amount };
    },
  },
  watch: {
    filledOut() {
      this.$emit("updateFilledOut", this.filledOut);
    },
    discount() {
      if (this.discounts) {
        let id = this.discounts.codes[this.discount];
        if (id) this.$emit("updateDiscount", this.discount);
      }
    },
  },
  data() {
    return {
      countries: countries.sort((a, b) => {
        return a.text > b.text ? 1 : -1;
      }),
      discount: "",
      discounts: null,
    };
  },
  methods: {
    getDiscountCodes() {
      fetch(`${process.env.VUE_APP_BASE_URL}/account/discounts`, {
        method: "GET",
        redirect: "follow",
        credentials: "include",
      })
        .then((result) => result.json())
        .then((result) => {
          this.discounts = result;
        });
    },
    updateFirstName(value) {
      this.$store.commit("updateUserDataProp", {
        prop: "firstname",
        value,
      });
    },
    updateLastName(value) {
      this.$store.commit("updateUserDataProp", {
        prop: "lastname",
        value,
      });
    },
    updateEmail(value) {
      this.$store.commit("updateUserDataProp", {
        prop: "email",
        value,
      });
    },
    updateStreet(value) {
      this.$store.commit("updateUserDataProp", {
        prop: "street",
        value,
      });
    },
    updateZipcode(value) {
      this.$store.commit("updateUserDataProp", {
        prop: "zipcode",
        value,
      });
    },
    updateCity(value) {
      this.$store.commit("updateUserDataProp", {
        prop: "city",
        value,
      });
    },
    updateCountry(value) {
      this.$store.commit("updateUserDataProp", {
        prop: "country",
        value,
      });
    },
    updateIsCompany(value) {
      this.$store.commit("updateUserDataProp", {
        prop: "isCompany",
        value,
      });
    },
    updateCompanyName(value) {
      this.$store.commit("updateUserDataProp", {
        prop: "companyName",
        value,
      });
    },
  },
  mounted() {
    this.$store.dispatch("fetchUserData");
    this.$emit("updateFilledOut", this.filledOut);
    this.getDiscountCodes();
  },
};
</script>

<style lang="scss" scoped>
#form-wrap {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  min-width: 500px;
  margin: auto;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  padding-top: 50px;
  section {
    width: 40%;
    margin-left: 5%;
    margin-right: 5%;
  }
}

#code-section {
  min-height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  div:first-child {
    width: 100%;
  }
}

label {
  margin-top: 2em;
}

*:valid {
  border: solid 1px green;
}

*:invalid {
  border: solid 1px orange;
}

@media only screen and (max-width: 1080px) {
  #form-wrap {
    min-width: 0;
    overflow-y: auto;
    section {
      width: 100%;
    }
  }
}

#subscription-rebate {
  color: #52cc99;
  width: fit-content;
  white-space: nowrap;
  text-align: center;
}
</style>
