<template>
  <div
    id="spinner"
    :style="{
      borderTop: `solid 5px ${color}`,
      width: size,
      height: size,
      minWidth: size,
      minHeight: size,
    }"
  ></div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Spinner",
  props: {
    color: String,
    size: {
      type: String,
      default: "20px",
      required: false,
    },
  },
  components: {},
  data() {
    return {};
  },
  methods: {},
  computed: {},
};
</script>

<style scoped lang="scss">
#spinner {
  border-radius: 50%;
  border: solid 5px transparent;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
