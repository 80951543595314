import Vue from "vue";
import VueRouter from "vue-router";
import SignupForm from "../components/SignupForm.vue";
import LoginForm from "../components/LoginForm.vue";
import ForgotPasswordForm from "../components/ForgotPasswordForm.vue";
import ResetPasswordForm from "../components/ResetPasswordForm.vue";
import Index from "../views/Index.vue";
import Account from "../views/Account.vue";
import Dashboard from "../views/Dashboard.vue";
import Subscribe from "../views/Subscribe.vue";
import SubscribeForm1 from "../components/SubscribeForm1.vue";
import SubscribeForm2 from "../components/SubscribeForm2.vue";
import SubscribeForm3 from "../components/SubscribeForm3.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/",
    name: "Index",
    component: Index,
    props: true,
    children: [
      {
        path: "login",
        name: "LoginForm",
        components: {
          form: LoginForm,
        },
        props: true,
      },
      {
        path: "signup",
        name: "SignupForm",
        components: {
          form: SignupForm,
        },
        props: true,
      },
      {
        path: "forgot",
        name: "ForgotPasswordForm",
        components: {
          form: ForgotPasswordForm,
        },
        props: true,
      },
      {
        path: "reset",
        name: "ResetPasswordForm",
        components: {
          form: ResetPasswordForm,
        },
        props: true,
      },
    ],
  },
  {
    path: "/account",
    name: "Account",
    component: Account,
  },
  {
    path: "/subscribe",
    name: "subscribe",
    component: Subscribe,
    children: [
      { path: "/", name: "Subscribe1", component: SubscribeForm1 },
      { path: "2", name: "Subscribe2", component: SubscribeForm2 },
      { path: "3", name: "Subscribe3", component: SubscribeForm3 },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
