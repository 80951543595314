<template>
  <div id="wrapper">
    <div id="card-wrapper">
      <div id="card">
        <div id="logo">
          <img src="/img/logoWhite.svg" />
          <div id="title">{{ title }}</div>
        </div>
        <template>
          <div id="error" v-if="error" v-html="error"></div>
          <router-view
            id="form"
            name="form"
            @welcome="welcomeTitle = true"
            @error="error = $event"
          ></router-view>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Index",
  computed: {
    title() {
      switch (this.$route.path) {
        case "/login":
          return "LOGIN";
        case "/signup":
          return this.welcomeTitle ? "WELCOME" : "CREATE ACCOUNT";
        case "/forgot":
          return "RESET PASSWORD";
        case "/reset":
          return "SET NEW PASSWORD";
        default:
          return "TITLE";
      }
    },
  },
  data() {
    return {
      welcomeTitle: false,
      error: "",
    };
  },
};
</script>

<style lang="scss">
#error {
  width: 70%;
  background: #e11d1d;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  color: white;
  padding: 5px;
  padding-left: 8px;
  padding-right: 8px;
  margin-bottom: 20px;
  margin-left: 15%;
  box-sizing: border-box;
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0.1em;
}

#wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 50;
}

@keyframes backgroundMovement {
  0% {
    top: 0;
    left: 0;
  }
  25% {
    top: -8%;
    left: -15%;
  }
  50% {
    top: -20%;
    left: -20%;
  }
  75% {
    top: -13%;
    left: -9%;
  }
  100% {
    top: 0;
    left: 0;
  }
}

#card-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
  #card {
    position: relative;
    width: 20vw;
    min-width: 350px;
    padding-top: 30px;
    padding-bottom: 40px;
    background-color: rgba(242, 242, 242, 0.7);
    box-shadow: 0px 4px 50px rgba(255, 255, 255, 0.25),
      0px 4px 50px rgba(255, 255, 255, 0.25);
    border-radius: 3px;
    & #form {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    & #logo {
      position: absolute;
      bottom: calc(100% + 20px);
      width: 110%;
      left: -5%;
      img {
        width: 100%;
      }
      #title {
        width: 200%;
        margin-left: -50%;
        margin-top: 50px;
        font-weight: 900;
        font-size: 48px;
        line-height: 59px;
        letter-spacing: 0.15em;
        color: #ffffff;
        text-shadow: 0px 4px 20px rgba(244, 244, 244, 0.5);
      }
    }
  }
}

#right-btn,
#center-btn {
  text-transform: uppercase;
  border: none;
  color: #ffffff;
  background: #ff762b;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 5px;
  width: 50%;
  padding-top: 5px;
  padding-bottom: 5px;
  transition: background-color 0.2s;
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 34px;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.1em;
  font-weight: bold;
  &:hover,
  &:focus {
    cursor: pointer;
    border: solid 2px #ff9700;
    outline: none;
  }
  &:disabled {
    border: none;
    background-color: #bd9762;
    cursor: default;
  }
}

#center-btn {
  width: 70%;
}

#left-btn {
  text-transform: uppercase;
  box-sizing: border-box;
  border-radius: 5px;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.1em;
  width: 50%;
  background-color: transparent;
  min-height: 34px;
  min-width: 136px;
  font-family: Work Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  border: none;
  letter-spacing: 0.05em;
  color: #0a2540;
  &:hover,
  &:focus {
    cursor: pointer;
    color: #ff762b;
    outline: none;
  }
}

#form-buttons {
  padding-top: 1rem;
  width: 70%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

a {
  float: left;
  margin-left: 15%;
  margin-top: 7px;
  text-decoration: none;
  color: #ddd;
}

@media only screen and (max-width: 1080px) {
  #card {
    min-width: 90% !important;
    max-height: 60%;
    margin-bottom: -100px;
    button {
      float: none;
      margin: auto;
    }
  }
  #logo {
    img {
      width: 70% !important;
    }
    #title {
      margin-top: 10px !important;
      font-size: 36px !important;
      max-width: 100%;
      margin-left: 0 !important;
      line-height: 1em !important;
    }
  }
}
</style>
