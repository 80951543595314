<template>
  <button @click="$emit('click')">
    <Spinner :color="textColor" v-if="loading && !failed" />
    {{ loading || failed ? "" : text }}
    {{ failed ? "error" : "" }}
  </button>
</template>

<script>
// @ is an alias to /src
import Spinner from "@/components/Spinner.vue";

export default {
  name: "LoadingButton",
  props: {
    mainColor: String,
    textColor: String,
    hoverColor: String,
    loading: Boolean,
    text: String,
    failed: Boolean,
  },
  components: { Spinner },
  data() {
    return {};
  },
  methods: {},
  computed: {},
};
</script>

<style scoped lang="scss">
button {
  margin-left: auto;
  background-color: black;
  color: white;
  border: none;
  border-radius: 3px;
  margin-top: 10px;
  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
  min-height: 40px;
  min-width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:focus,
  &:hover {
    outline: none;
    cursor: pointer;
    background-color: #ff752c;
  }
}
</style>
