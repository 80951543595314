<template>
  <div>
    <img :src="img" id="icon" />
    <label>{{ name }}</label>
    <div
      id="value"
      :name="name"
      :placeholder="placeholder"
      :tabindex="tabindex"
    >
      {{ text }}
    </div>
    <img id="edit" src="/img/icons/edit.svg" @click="startEditing" />
    <div id="edit-popup" v-if="editing">
      <img :src="img" />
      <div>Update {{ name }}</div>

      <input v-model="newMail" :type="type" :placeholder="placeholder" />
      <input v-model="password" type="password" placeholder="password" />

      <LoadingButton
        @click="changeEmail"
        mainColor="black"
        textColor="white"
        hoverColor="orange"
        text="CHANGE"
        :loading="loading"
        :failed="failed"
      ></LoadingButton>
    </div>
    <div id="popup-backgrop" v-if="editing" @click="editing = false"></div>
  </div>
</template>

<script>
// @ is an alias to /src
import LoadingButton from "@/components/LoadingButton.vue";

export default {
  name: "ChangeMail",
  props: {
    type: String,
    name: String,
    value: null,
    placeholder: String,
    tabindex: String,
    img: String,
  },
  components: { LoadingButton },
  data() {
    return {
      editing: false,
      newMail: "",
      password: "",
      loading: false,
      failed: false,
    };
  },
  methods: {
    startEditing() {
      this.editing = true;
      this.loading = false;
      this.failed = false;
      this.password = "";
      this.newMail = "";
      if (Array.isArray(this.value)) {
        this.editValue = JSON.parse(JSON.stringify(this.value));
      } else this.editValue = this.value;
    },
    changeEmail() {
      if (!this.loading) {
        this.loading = true;
        this.failed = false;
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
        urlencoded.append("newEmail", this.newMail);
        urlencoded.append("password", this.password);

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: urlencoded,
          redirect: "follow",
          credentials: "include",
        };
        fetch(
          `${process.env.VUE_APP_BASE_URL}/auth/changeemail`,
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            if (result.success) {
              this.$emit("input", this.newMail);
              this.newMail = "";
              this.password = "";
              this.editing = false;
              this.loading = false;
            } else {
              this.failed = true;
            }
          });
      }
    },
  },
  computed: {
    text() {
      if (this.type === "password") return "********";
      if (Array.isArray(this.value)) {
        let combinedValue = this.value[0].data;
        for (let i = 1; i < this.value.length; i++) {
          combinedValue += ` ${this.value[i].data}`;
        }
        return combinedValue;
      }
      return this.value === "" ? this.placeholder : this.value;
    },
  },
};
</script>

<style scoped lang="scss">
div {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin-bottom: 20px;

  #icon {
    width: 12px;
    position: absolute;
    left: 15px;
    top: 6px;
  }
  #edit {
    position: absolute;
    right: 15px;
    top: 12px;
    &:hover {
      cursor: pointer;
    }
  }
  label {
    color: black;
    position: absolute;
    left: 35px;
    top: 5px;
    padding-left: 2px;
    padding-right: 2px;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    pointer-events: none;
  }
}
#value {
  box-sizing: border-box;
  border: 1px solid rgba(66, 84, 102, 0.2);
  border-radius: 5px;
  margin: 0;
  padding: 8px;
  padding-left: 35px;
  padding-top: 27px;
  padding-bottom: 16px;
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
  background: none;
  line-height: 1;

  text-align: left;
  /* Browsers have different default form fonts */
  font-family: "Montserrat", sans-serif;
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  height: 34px;
  border-radius: 5px;
  letter-spacing: 0.05em;
  &::placeholder {
    font-weight: normal;
  }
}

#popup-backgrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 49;
  background-color: rgba(0, 0, 0, 0.3);
}

#edit-popup {
  position: fixed;
  top: 20vh;
  left: 40vw;
  min-height: 20vh;
  max-height: 75vh;
  width: 20vw;
  background-color: white;
  border-radius: 5px;
  border: solid 1px black;
  z-index: 50;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  input {
    border: none;
    border-bottom: solid 1px black;
    transition: border-bottom 0.1s;
    font-size: 14px;
    font-weight: bold;
    width: 100%;
    margin-top: 20px;
    &:focus {
      outline: none;
      border-bottom: solid 1px #ff752c;
    }
    &:hover {
      border-bottom: solid 1px #ff752c;
    }
  }
  div {
    display: block;
    text-align: left;
    font-size: 10px;
  }
  img {
    position: absolute;
    top: 10px;
    left: 10px;
    height: 10px;
  }
}
</style>
