<template>
  <div id="selector-wrapper">
    <div
      v-for="(item, index) in platforms"
      :key="index"
      class="item"
      :style="{
        borderBottom:
          value == item.data ? '2px solid #6166EA' : 'solid 2px transparent',
      }"
      @click="updateValue(item.data)"
    >
      {{ item.name }}
    </div>
  </div>
</template>

<script>
export default {
  name: "PlatformSelector",
  props: {
    platforms: Array,
    value: String,
  },
  components: {},
  data() {
    return {};
  },
  methods: {
    updateValue: function (value) {
      this.$emit("input", value);
    },
  },
};
</script>

<style scoped lang="scss">
#selector-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.item {
  color: white;
  font-weight: bold;
  &:hover {
    cursor: pointer;
  }
}
</style>
