<template>
  <div>
    <template v-if="!afterSignup">
      <label>E-MAIL</label>
      <FancyInput
        type="text"
        name="email"
        :value="email"
        @input="email = $event"
        placeholder="mail@example.com"
        width="70%"
        tabindex="1"
        @enter="trySubmit"
      />
      <label>PASSWORD</label>
      <FancyInput
        type="password"
        name="password"
        v-model="password"
        placeholder="enter your password..."
        width="70%"
        tabindex="2"
        @enter="trySubmit"
      />
      <label>RE-ENTER PASSWORD</label>
      <FancyInput
        type="password"
        name="confirmPassword"
        v-model="confirmPassword"
        placeholder="repeat your password..."
        width="70%"
        tabindex="3"
        @enter="trySubmit"
      />
      <label class="legal-checkbox">
        <input type="checkbox" v-model="tos" tabindex="4" />
        <span>
          I have read and agree to our
          <a
            href="https://merchcosmos.com/tos.html"
            target="_blank"
            rel="noopener noreferrer"
            tabindex="5"
            >TERMS AND CONDITIONS</a
          >
        </span>
      </label>
      <label class="legal-checkbox">
        <input type="checkbox" v-model="privacy" tabindex="6" />
        <span>
          I have read and agree to our
          <a
            href="https://merchcosmos.com/privacy.html"
            target="_blank"
            rel="noopener noreferrer"
            tabindex="7"
            >PRIVACY POLICY</a
          >
        </span>
      </label>
      <div id="form-buttons">
        <router-link
          tag="button"
          id="left-btn"
          :to="{ name: 'LoginForm', params: { email } }"
          tabindex="8"
        >
          Sign in instead
        </router-link>
        <button
          id="right-btn"
          @click="signUp"
          tabindex="9"
          :disabled="!(tos && privacy)"
        >
          {{ loading ? "" : "Sign up" }}
          <Spinner color="#ffffff" class="spinner" v-if="loading" />
        </button>
      </div>
    </template>
    <template v-else>
      <p id="welcome-text">
        Hello there,<br />
        thank you for signing up to MerchCosmos! You are only one step away from
        understanding your Business` sales development. Please check your inbox
        to confirm your e-mail address.
      </p>
      <a :href="extensionLink">Get the extension here</a>
      <router-link
        id="center-btn"
        :to="signupRedirect"
        tabindex="9"
        tag="button"
      >
        go to dashboard
      </router-link>
    </template>
  </div>
</template>

<script>
import FancyInput from "@/components/FancyInput.vue";
import Spinner from "@/components/Spinner.vue";
export default {
  name: "Signup",
  components: {
    FancyInput,
    Spinner,
  },
  data() {
    return {
      email: this.$route.params.email ?? "",
      password: "",
      confirmPassword: "",
      afterSignup: false,
      loading: false,
      tos: false,
      privacy: false,
      signupRedirect: { name: "LoginForm", params: { email: this.email } },
      extensionLink: process.env.VUE_APP_EXTENSION_LINK,
    };
  },
  methods: {
    trySubmit() {
      if (!(this.email && this.password && this.confirmPassword))
        this.$emit("error", "Please fill out all fields");
      else if (!(this.tos && this.privacy))
        this.$emit("error", "You need to accept ToS & Privacy");
      else this.signUp();
    },
    signUp() {
      this.$emit("error", "");
      this.loading = true;

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: this.email,
          password1: this.password,
          password2: this.confirmPassword,
          tosAccepted: this.tos,
          privacyAccepted: this.privacy,
        }),
        redirect: "follow",
        credentials: "include",
      };
      fetch(`${process.env.VUE_APP_BASE_URL}/auth/signup`, requestOptions)
        .then(async (response) => {
          return {
            status: response.status,
            ...(await response.json()),
          };
        })
        .then((result) => {
          this.loading = false;
          if (result.success) {
            this.$emit("error", "");
            this.$emit("welcome");
            this.afterSignup = true;
            return;
          }
          if (result.status === 500) this.$emit("error", result.msg);
          else
            this.$emit(
              "error",
              result.errors.map((rawError) => rawError.msg).join("<br>")
            );
        });
    },
  },
  mounted() {
    if (window.location.hash.length > 1) {
      const initialPlan = window.location.hash === "#anually" ? "8qrr" : "gpxw";
      this.$store.commit("updateUserDataProp", {
        prop: "subscriptionPlan",
        value: initialPlan,
      });
      this.signupRedirect = {
        name: "subscribe",
        params: { initialStep: 1 },
      };
    }
  },
};
</script>

<style scoped lang="scss">
* {
  margin-bottom: 10px;
}

.spinner {
  width: 12px !important;
  height: 12px !important;
  min-width: 12px !important;
  min-height: 12px !important;
  border-width: 5px !important;
}

label {
  font-family: Work Sans;
  font-weight: bold;
  margin-right: auto;
  margin-left: 15%;
  color: #0a2540;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 5px;
  letter-spacing: 0.1em;
}

a {
  color: #6166ea;
  letter-spacing: 0.05em;
  font-size: 14px;
  margin: 0;
  margin-bottom: 40px;
}

#welcome-text {
  font-weight: bold;
  font-size: 12px;
  line-height: 17px;
  text-align: left;
  letter-spacing: 0.05em;
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 30px;
  width: 80%;
  color: #000000;
}

.legal-checkbox {
  display: flex;
  font-weight: bold !important;
  font-size: 10px !important;
  line-height: 12px !important;

  color: #606060 !important;
  width: 70%;
  span {
    text-align: left;
    padding-left: 5px;
    box-sizing: border-box;
    a {
      margin: 0;
      float: none;
      color: #606060 !important;
      text-decoration: underline;
    }
  }
}
</style>
