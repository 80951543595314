<template>
  <div id="wrapper">
    <div id="inner-wrapper">
      <img src="/img/logoWhite.svg" />
      <div id="progress">
        <div
          id="step1"
          :style="{ background: step === 0 ? '#9866FE' : 'transparent' }"
        >
          Billing Cycle
        </div>
        <div
          id="step2"
          :style="{ background: step === 1 ? '#9866FE' : 'transparent' }"
        >
          Personal Data
        </div>
        <div
          id="step3"
          :style="{ background: step === 2 ? '#9866FE' : 'transparent' }"
        >
          Payment Method
        </div>
        <div
          id="step4"
          :style="{ background: step === 3 ? '#9866FE' : 'transparent' }"
        >
          Success
        </div>
      </div>
      <div id="drawer" :style="{ left: leftOffset }">
        <div id="s1" class="card">
          <SubscribeForm1 @next="chooseSubscription"></SubscribeForm1>
        </div>
        <div id="s2" class="card">
          <SubscribeForm2
            @updateFilledOut="updateFilledOut"
            @updateDiscount="updateDiscount"
          ></SubscribeForm2>
        </div>
        <div id="s3" class="card">
          <SubscribeForm3
            @submit="step = 3"
            :refcode="discountCode"
          ></SubscribeForm3>
        </div>
        <div class="card" id="last-card">
          <template v-if="step === 3">
            <img
              v-for="n in 50"
              :key="n"
              :id="`confetti-${n}`"
              src="/img/icons/dollar.svg"
            />
          </template>
          <h1>You're all set!</h1>
          <p>
            Thank you for purchasing MerchCosmos Pro.
            <br />Jump right into the Dashboard to grow your sales!
          </p>
          <img src="/img/lineChart.png" id="line-chart" />
        </div>
      </div>
      <div id="buttons">
        <span>
          <button id="prev" v-if="step > 0 && step != 3" @click="step--">
            Prev.
          </button>
        </span>
        <span>
          <button id="next" v-if="nextVisible" @click="next">Next</button>
          <button id="submit" v-show="canSubmit" disabled>Submit</button>
          <a id="dashboard" v-if="step === 3" href="/">To Dashbord</a>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import SubscribeForm1 from "@/components/SubscribeForm1.vue";
import SubscribeForm2 from "@/components/SubscribeForm2.vue";
import SubscribeForm3 from "@/components/SubscribeForm3.vue";
import { mapState } from "vuex";

export default {
  name: "Subscribe",
  components: {
    SubscribeForm1,
    SubscribeForm2,
    SubscribeForm3,
  },
  props: {
    initialStep: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      step: this.initialStep,
      form2FilledOut: false,
      discountCode: "",
    };
  },
  computed: {
    ...mapState({
      isPro: (state) => state.isPro,
    }),
    leftOffset() {
      let options = ["25%", "-45%", "-115%", "-185%"];
      return options[this.step];
    },
    nextVisible() {
      return (
        this.step < 2 &&
        this.step > 0 &&
        !(this.step === 1 && !this.form2FilledOut)
      );
    },
    canSubmit() {
      return this.step === 2;
    },
  },
  watch: {
    isPro() {
      if (this.isPro) this.$router.push("/");
    },
  },
  methods: {
    updateDiscount(e) {
      this.discountCode = e;
    },
    chooseSubscription() {
      setTimeout(() => {
        this.step = 1;
      }, 500);
    },
    updateFilledOut(e) {
      this.form2FilledOut = e;
    },
    next() {
      if (this.step === 2) {
        this.$store.dispatch("sendUserData");
      }
      this.step++;
    },
  },
  mounted() {
    if (this.isPro) this.$router.push("/");
  },
};
</script>

<style lang="scss" scoped>
#wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 50;
  font-family: Work Sans !important;
}

#progress {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.02em;

    color: #ffffff;
    background: transparent;
    border-radius: 25px;
    padding: 5px 15px 5px 15px;
    margin-left: 15px;
    margin-right: 15px;
  }
}
#inner-wrapper {
  width: 90%;
  height: 90%;
  position: absolute;
  top: 5%;
  left: 5%;
  background: #ffffff42;
  backdrop-filter: blur(100px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  img {
    width: 30%;
    margin-left: auto;
    margin-right: auto;
  }
  #drawer {
    display: flex;
    flex-wrap: nowrap;
    width: auto;
    height: 60%;
    position: relative;
    transition: left 0.5s ease-in-out;
  }
  #buttons {
    width: 50%;
    margin-left: 25%;
    display: flex;
    justify-content: space-between;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.2px;
    min-height: 50px;
    color: #0a2540;
    button,
    #dashboard {
      background: #ffffff;
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04),
        0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
      border-radius: 50px;
      padding: 5px 15px 5px 15px;
      border: none;
      font-family: Work Sans;
      text-decoration: none;
      &:hover {
        cursor: pointer;
      }
    }
    #prev::before {
      content: url("/img/icons/arrowLeft.svg");
      margin-right: 5px;
    }
    #next::after {
      content: url("/img/icons/arrowRight.svg");
      margin-left: 5px;
    }
    #submit,
    #dashboard {
      background-color: #6166ea;
      color: white;
      &:disabled {
        opacity: 0.7;
        &:hover {
          cursor: default;
        }
      }
    }
  }
}
.card {
  width: 50%;
  flex-shrink: 0;
  height: 100%;
  background-color: white;
  margin-right: 20%;
  box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04),
    0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}
#last-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  #line-chart {
    width: 60%;
  }
}

[id|="confetti"] {
  position: absolute;
  margin: 0;
}

@for $i from 0 through 50 {
  $w: random(8);
  $l: random(115);
  #confetti-#{$i} {
    width: #{$w}px;
    height: #{$w * 5}px;
    top: -50px;
    left: unquote($l - 15 + "%");
    opacity: random() + 0.5;
    transform: rotate(#{random() * 360}deg);
    animation: drop-#{$i}
      unquote(3 + random() + "s")
      linear
      unquote(random(30) / 10 + "s")
      infinite;
  }

  @keyframes drop-#{$i} {
    100% {
      top: 110%;
      left: unquote($l + random(30) - 30 + "%");
    }
  }
}

@media only screen and (max-width: 1080px) {
  #progress {
    display: none;
  }
  .card {
    width: 70%;
    margin-left: -15%;
  }
  #buttons {
    width: 90% !important;
    margin-left: 5% !important;
  }
  #inner-wrapper img {
    width: 80%;
  }
}
</style>
