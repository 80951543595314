<template>
  <div id="account">
    <div id="delete-account-popup" v-if="deleteAccount.popupOpen">
      <img id="x" @click="cancelDeletion" src="/img/icons/x.svg" />
      <h1>Delete you Account?</h1>
      <p class="danger">
        You are still running a paid MerchCosmos plan. If you close your
        account, the subcription will be cancelled and you will lose acces to
        the paid PRO features
      </p>
      <h4>Please enter your password for confirmation</h4>
      <h3 v-if="deleteAccount.error">Incorrect password!</h3>
      <label id="input">
        <span>Password</span>
        <img src="/img/icons/locked.svg" />
        <input
          v-model="deleteAccount.password"
          type="password"
          placeholder="type your password..."
        />
      </label>
      <div id="buttons">
        <button id="cancel" @click="cancelDeletion">Cancel</button>
        <button id="delete" @click="deleteMCAccount">Delete Account</button>
      </div>
    </div>
    <div id="delete-account-popup" v-if="helpOpen">
      <img id="x" @click="helpOpen = false" src="/img/icons/x.svg" />
      <h1>Need some help?</h1>
      <p>
        Please send us your message below, we will take on your problem or
        request as soon as possible. Don’t forget to check the spam folder in
        your emails ;)
      </p>
      <textarea v-model="helpMessage" placeholder="Type your message here..." />
      <div id="buttons">
        <button id="cancel" @click="helpOpen = false">Cancel</button>
        <button id="delete" @click="sendHelpMessage">Submit</button>
      </div>
    </div>
    <div id="delete-account-popup" v-if="cancelOpen">
      <img id="x" @click="cancelOpen = false" src="/img/icons/x.svg" />
      <h1>Sorry to see you go!</h1>
      <p>
        Do you really want to cancel your subscription? Your subscription will
        end with the next billing period and you will lose acces to all PRO
        features. You will still be able to use the free version of MerchCosmos
        and resubscribe anytime.
      </p>
      <img id="sadface" src="/img/sadface.svg" />
      <div id="buttons">
        <button id="cancel" @click="cancelOpen = false">Back</button>
        <button id="delete" @click="cancelSubscription">
          Cancel Subscription
        </button>
      </div>
    </div>
    <Header></Header>
    <div id="data-wrapper">
      <section id="personal">
        <h2>Personal Data</h2>
        <div id="body">
          <AccountInput
            type="text"
            name="Name"
            :value="this.firstname"
            @input="updateFirstName"
            placeholder="type your firstname..."
            tabindex="1"
            img="/img/icons/name.svg"
            editable
          />
          <AccountInput
            type="text"
            name="Name"
            :value="this.lastname"
            @input="updateLastName"
            placeholder="type your lastname..."
            tabindex="1"
            img="/img/icons/name.svg"
            editable
          />
          <AccountInput
            type="text"
            name="Address"
            :value="this.street"
            @input="updateStreet"
            placeholder="type your addess..."
            tabindex="2"
            img="/img/icons/place.svg"
            editable
          />
          <AccountInput
            type="text"
            name="City"
            :value="this.city"
            @input="updateCity"
            placeholder="type your city..."
            tabindex="3"
            img="/img/icons/city.svg"
            editable
          />
          <AccountInput
            type="text"
            name="ZIP Code"
            :value="this.zipcode"
            @input="updateZipcode"
            placeholder="type your zip code..."
            tabindex="4"
            img="/img/icons/zip.svg"
            editable
          />
          <AccountDropDown
            name="Country"
            :value="this.country"
            @input="updateCountry"
            tabindex="5"
            img="/img/icons/world.svg"
            :options="countries"
            placeholder="Choose a country"
          />
        </div>
      </section>
      <section id="credentials">
        <h2>Account Credentials</h2>
        <div id="body">
          <ChangeMail
            type="text"
            name="email"
            :value="this.email"
            placeholder="mail@example.com"
            tabindex="7"
            img="/img/icons/email.svg"
          />
          <ChangePassword
            type="password"
            name="Password"
            v-model="this.newMail"
            placeholder="***********"
            tabindex="7"
            img="/img/icons/locked.svg"
          />
          <AccountSwitch
            type="text"
            name="Account Type"
            :value="this.isAffiliate || this.isCompany"
            :disabled="this.isAffiliate"
            @input="updateIsCompany"
            option1="Private"
            option2="Company"
            placeholder="***********"
            tabindex="7"
            img="/img/icons/arrows.svg"
          />
          <AccountInput
            v-if="this.isCompany"
            type="text"
            name="Company Name"
            :value="this.companyName"
            @input="updateCompanyName"
            placeholder="type company name..."
            tabindex="11"
            img="/img/icons/city.svg"
            editable
          />
        </div>
      </section>
      <section id="billing" v-if="paymentMethod">
        <h2>Billing Information</h2>
        <div id="body">
          <AccountInput
            type="text"
            name="Payment Method"
            placeholder="Credit Card / Paypal"
            :value="paymentMethod.type"
            tabindex="11"
            :img="paymentMethod.imageUrl"
          />
          <AccountInput
            v-if="paymentMethod.type === 'paypal'"
            type="text"
            name="E-Mail"
            placeholder="mail@example.com"
            :value="paymentMethod.email"
            tabindex="11"
            img="/img/icons/locked.svg"
          />
          <AccountInput
            v-if="paymentMethod.type === 'creditcard'"
            type="text"
            name="Card Number"
            placeholder="*************"
            :value="paymentMethod.maskedNumber"
            tabindex="11"
            img="/img/icons/locked.svg"
          />
          <p>
            you are currently running a paid MerchCosmos plan. You can cancel
            your subscription
            <a href="#" @click="cancelOpen = true">here.</a>
          </p>
        </div>
      </section>
      <section id="billing-history">
        <h2>Billing History</h2>
        <div id="body">
          <AccountInput
            v-for="bill in bills"
            :key="bill.name"
            type="download"
            :downloadLink="bill.download"
            :name="bill.date"
            v-model="bill.name"
            placeholder="RE_20200612"
            tabindex="7"
            img
          />
          <p v-if="bills.length === 0">You do not have any Bills</p>
        </div>
      </section>
      <section id="affiliate" v-if="isAffiliate">
        <h2>Affiliate Program</h2>
        <div id="body">
          <AccountInput
            type="text"
            name="Personal Discount Code"
            v-model="referralId"
            placeholder="BETA"
            tabindex="7"
            img
          />
          <AccountInput
            type="text"
            name="Referred Users"
            v-model="totalReferred"
            placeholder
            tabindex="8"
            img
          />
          <AccountInput
            type="text"
            name="Total Referral Earnings"
            v-model="cashAllTime"
            placeholder
            tabindex="9"
            img
          />
          <AccountInput
            type="text"
            name="Current Balance"
            v-model="cashLastPayout"
            placeholder
            tabindex="10"
            img
          />
          <AccountInput
            type="text"
            name="Next Payout Date"
            :value="nextPayout"
            placeholder
            tabindex="11"
            img
          />
        </div>
      </section>

      <section id="emails">
        <h2>Email Settings</h2>
        <div id="body">
          <label>
            <input
              type="checkbox"
              :checked="importantEmails"
              @input="updateImportantEmails"
            />
            I want to receive important, security related Emails
          </label>
          <br />
          <label>
            <input
              type="checkbox"
              :checked="newsletter"
              @input="updateNewsletter"
            />
            I want to receive the newsletter
          </label>
        </div>
      </section>
      <section id="misc">
        <h2>Miscellaneous</h2>
        <div id="body">
          <a id="export" :href="downloadDataUrl" download
            >Export personal Data</a
          >
          <a id="delete" @click="deleteAccount.popupOpen = true"
            >Delete my Account</a
          >
        </div>
      </section>
      <section id="payout" v-if="isAffiliate">
        <h2>Payout History</h2>
        <div id="body">
          <AccountInput
            v-for="creditnote in creditnotes"
            :key="creditnote.name"
            type="download"
            :downloadLink="creditnote.download"
            :name="creditnote.date"
            v-model="creditnote.name"
            placeholder="RE_20200612"
            tabindex="7"
            img
          />
        </div>
      </section>
    </div>

    <Footer @help="helpOpen = true" />
    <button id="save-button" v-if="savable" @click="save">Save</button>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import AccountInput from "@/components/AccountInput.vue";
import AccountDropDown from "@/components/AccountDropDown.vue";
import AccountSwitch from "@/components/AccountSwitch.vue";
import ChangeMail from "@/components/ChangeMail.vue";
import ChangePassword from "@/components/ChangePassword.vue";
import { countries } from "@/countries.js";
import { mapState } from "vuex";
export default {
  name: "Account",
  components: {
    Header,
    Footer,
    AccountInput,
    AccountDropDown,
    AccountSwitch,
    ChangeMail,
    ChangePassword,
  },
  computed: {
    nextPayout() {
      let date = new Date();
      let thisMonthsCutoff = new Date();
      thisMonthsCutoff.setUTCDate(15);
      thisMonthsCutoff.setUTCHours(0);
      thisMonthsCutoff.setUTCMinutes(0);
      thisMonthsCutoff.setUTCSeconds(0);
      if (date > thisMonthsCutoff) {
        thisMonthsCutoff.setUTCMonth(thisMonthsCutoff.getUTCMonth() + 1);
        return thisMonthsCutoff.toLocaleDateString();
      } else {
        return thisMonthsCutoff.toLocaleDateString();
      }
    },
    name() {
      return [
        { data: this.firstname, name: "Elliot" },
        { data: this.lastname, name: "Example" },
      ];
    },
    downloadDataUrl() {
      return `${process.env.VUE_APP_BASE_URL}/account/data`;
    },
    ...mapState({
      firstname: (state) => state.userData.firstname,
      lastname: (state) => state.userData.lastname,
      email: (state) => state.userData.email,
      street: (state) => state.userData.street,
      zipcode: (state) => state.userData.zipcode,
      city: (state) => state.userData.city,
      country: (state) => state.userData.country,
      isCompany: (state) => state.userData.isCompany,
      companyName: (state) => state.userData.companyName,
      importantEmails: (state) => state.userData.importantEmails,
      newsletter: (state) => state.userData.newsletter,
      isAffiliate: (state) => state.userData.isAffiliate,
      totalReferred: (state) => state.userData.totalReferred,
      cashAllTime: (state) => state.userData.cashAllTime,
      cashLastPayout: (state) => state.userData.cashLastPayout,
      referralId: (state) => state.userData.referral_id,
      paymentMethod: (state) => state.paymentMethods.defaultPayment,
    }),
  },
  data() {
    return {
      bills: [],
      creditnotes: [],
      deleteAccount: {
        password: "",
        popupOpen: false,
        error: false,
      },
      newPassword1: "",
      newPassword2: "",
      oldPassword: "",
      newMail: "",
      countries: countries.sort((a, b) => {
        return a.text > b.text ? 1 : -1;
      }),
      savable: false,
      helpOpen: false,
      helpMessage: "",
      cancelOpen: false,
    };
  },
  methods: {
    sendHelpMessage() {
      fetch(`${process.env.VUE_APP_BASE_URL}/account/support`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ message: this.helpMessage }),
        redirect: "follow",
        credentials: "include",
      });
    },
    //todo change email & change password cases
    fetchBills() {
      fetch(`${process.env.VUE_APP_BASE_URL}/account/bills`, {
        credentials: "include",
      })
        .then((data) => data.json())
        .then((json) => {
          this.bills = json.map((obj) => {
            let split = obj.split("_");
            let months = [
              "JANUARY",
              "GEBRUARY",
              "MARCH",
              "APRIL",
              "MAI",
              "JUNE",
              "JULY",
              "AUGUST",
              "SEPTEMBER",
              "OCTOBER",
              "NOVEMBER",
              "DECEMBER",
            ];
            let month = months[Number.parseInt(split[2]) - 1];
            let year = split[1];
            return {
              name: obj,
              date: `${month} ${year}`,
              download: `${process.env.VUE_APP_BASE_URL}/account/bill/${obj}`,
            };
          });
        });
    },
    fetchCreditnotes() {
      fetch(`${process.env.VUE_APP_BASE_URL}/account/creditnotes`, {
        credentials: "include",
      })
        .then((data) => data.json())
        .then((json) => {
          this.creditnotes = json.map((obj) => {
            let split = obj.split("_");
            let months = [
              "JANUARY",
              "GEBRUARY",
              "MARCH",
              "APRIL",
              "MAI",
              "JUNE",
              "JULY",
              "AUGUST",
              "SEPTEMBER",
              "OCTOBER",
              "NOVEMBER",
              "DECEMBER",
            ];
            let month = months[Number.parseInt(split[2]) - 1];
            let year = split[1];
            return {
              name: obj.replace("creditnote", "credit"),
              date: `${month} ${year}`,
              download: `${process.env.VUE_APP_BASE_URL}/account/creditnote/${obj}`,
            };
          });
        });
    },
    cancelDeletion() {
      this.deleteAccount.popupOpen = false;
      this.deleteAccount.password = "";
      this.deleteAccount.error = false;
    },
    deleteMCAccount() {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      urlencoded.append("password", this.deleteAccount.password);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow",
        credentials: "include",
      };
      this.cancelSubscriptionCall();
      fetch(`${process.env.VUE_APP_BASE_URL}/account/delete`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.success) {
            alert("Your account has been deleted. You will no be redirected");
            window.location.href = "https://app.merchcosmos.com/login";
          } else {
            this.deleteAccount.error = true;
          }
        });
    },
    cancelSubscription() {
      this.cancelSubscriptionCall()
        .then(() => {
          this.cancelOpen = false;
          alert("Your subscription has been cancelled!");
        })
        .reject(() => {
          alert("Something went wrong :/");
        });
    },
    cancelSubscriptionCall() {
      return new Promise((resolve, reject) => {
        fetch(`${process.env.VUE_APP_BASE_URL}/account/cancel`, {
          credentials: "include",
        })
          .then((data) => data.json())
          .then((json) => {
            if (json.success) {
              resolve();
            } else reject();
          });
      });
    },
    save() {
      this.$store.dispatch("sendUserData");
      this.savable = false;
    },
    updateFirstName(value) {
      this.$store.commit("updateUserDataProp", {
        prop: "firstname",
        value,
      });
      this.savable = true;
    },
    updateLastName(value) {
      this.$store.commit("updateUserDataProp", {
        prop: "lastname",
        value,
      });
      this.savable = true;
    },
    updateEmail(value) {
      this.$store.commit("updateUserDataProp", {
        prop: "email",
        value,
      });
      this.savable = true;
    },
    updateStreet(value) {
      this.$store.commit("updateUserDataProp", {
        prop: "street",
        value,
      });
      this.savable = true;
    },
    updateZipcode(value) {
      this.$store.commit("updateUserDataProp", {
        prop: "zipcode",
        value,
      });
      this.savable = true;
    },
    updateCity(value) {
      this.$store.commit("updateUserDataProp", {
        prop: "city",
        value,
      });
      this.savable = true;
    },
    updateCountry(value) {
      this.$store.commit("updateUserDataProp", {
        prop: "country",
        value,
      });
      this.savable = true;
    },
    updateIsCompany(value) {
      this.$store.commit("updateUserDataProp", {
        prop: "isCompany",
        value,
      });
      this.savable = true;
    },
    updateCompanyName(value) {
      this.$store.commit("updateUserDataProp", {
        prop: "companyName",
        value,
      });
      this.savable = true;
    },
    updateImportantEmails(value) {
      let val = value.target.checked;
      this.$store.commit("updateUserDataProp", {
        prop: "importantEmails",
        value: val,
      });
      this.savable = true;
    },
    updateNewsletter(value) {
      let val = value.target.checked;
      this.$store.commit("updateUserDataProp", {
        prop: "newsletter",
        value: val,
      });
      this.savable = true;
    },
  },
  mounted() {
    fetch(`${process.env.VUE_APP_BASE_URL}/account/check`, {
      credentials: "include",
    })
      .then((data) => data.json())
      .then((json) => {
        if (json.loggedIn) {
          this.fetchBills();
          this.fetchCreditnotes();
          this.$store.dispatch("fetchUserData");
          this.$store.dispatch("fetchPaymentMethod");
        } else this.$router.push("/login");
      });
  },
};
</script>

<style>
body {
  position: static !important;
}
</style>

<style lang="scss" scoped>
#save-button {
  border: none;
  font-family: Work Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
  background: #6166ea;
  box-shadow: 0px 16px 24px rgba(97, 102, 234, 0.2),
    0px 2px 6px rgba(97, 102, 234, 0.12), 0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 20px;
  padding: 8px 50px 8px 50px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  margin-top: auto;
  z-index: 99;
  &:hover {
    cursor: pointer;
  }
}
#account {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 50;
  &::before {
    content: "";
    width: 100%;
    height: 50%;
    background-color: #f6f6f6;
    position: fixed;
    top: 50%;
    left: 0;
  }
}
#header {
  margin-left: 20px;
  margin-right: 20px;
  width: calc(100% - 40px);
}
#data-wrapper {
  box-sizing: border-box;
  width: calc(100% - 40px);
  margin-left: 20px;
  margin-right: 20px;
  padding-left: 10%;
  padding-right: 10%;
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-areas:
    "personal credentials billing billing-history"
    "affiliate email misc payout";
  grid-column-gap: 50px;
  padding-top: 100px; /*header spacing*/
  h1 {
    text-align: start;
    padding-left: 35%;
  }
  section {
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      h2 {
        color: white !important;
      }
    }
  }
}

#misc {
  a {
    font-family: Work Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
    flex: none;
    order: 0;
    align-self: center;
    padding: 10px 20px 10px 20px;
    margin: 10px;
    text-decoration: none;
    &:hover {
      cursor: pointer;
    }
  }
  #delete {
    background: #24b47e;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04),
      0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 50px;
  }
  #export {
    background: #3a3a3a;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04),
      0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 50px;
  }
}

section {
  margin-bottom: 100px;
  background-color: rgba(256, 256, 256, 0.28);
  box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04),
    0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  h2 {
    text-align: start !important;
    font-size: 16px;
    padding-left: 15px;
    box-sizing: border-box;
    color: #0a2540;
    font-family: Work Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  #body {
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    padding-bottom: auto;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  label {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    text-align: left;
    input {
      margin-right: 20px;
    }
  }
  p {
    font-family: Work Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #0a2540;
    text-align: left;
    a {
      color: #ff762b;
    }
  }
}

#delete-account-popup {
  position: fixed;
  top: 20vh;
  left: 20vw;
  width: 60vw;
  min-height: 40vh;
  background-color: white;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  box-sizing: border-box;
  padding: 50px 10% 100px 10%;
  max-height: 75vh;
  overflow-y: auto;
  textarea {
    max-width: 60%;
    min-width: 60%;
    min-height: 200px;
    border: 1px solid rgba(66, 84, 102, 0.2);
    border-radius: 5px;
    padding: 10px;
    box-sizing: border-box;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    &::placeholder {
      color: #425466;
    }
  }
  #x {
    width: 12px;
    height: 12px;
    position: absolute;
    top: 20px;
    right: 20px;
    &:hover {
      cursor: pointer;
    }
  }
  p {
    position: relative;
    width: 60%;
    text-align: left;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.05em;
    color: #000000;
    margin-bottom: 70px;
  }
  .danger::before {
    content: url("/img/icons/attention.svg");
    height: 20px;
    width: 20px;
    position: absolute;
    left: -80px;
    top: calc(50% - 10px);
  }
  h4 {
    width: 40%;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    text-align: left;
    color: #000000;
    margin-bottom: 10px;
  }
  h3 {
    width: 40%;
    font-size: 12px;
    background: #e11d1d;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    color: white;
    padding: 5px;
    padding-left: 8px;
    padding-right: 8px;
    margin-bottom: 20px;
    box-sizing: border-box;
  }
  #input {
    width: 40%;
    min-width: 150px;
    box-sizing: border-box;
    padding-left: 40px;
    padding-right: 20px;
    padding-bottom: 10px;
    padding-top: 20px;
    position: relative;
    border: 1px solid #828282;
    border-radius: 5px;
    &:focus-within {
      border: solid 1px #ff752c;
    }
    img {
      position: absolute;
      left: 12px;
      top: 4px;
      height: 12px;
      width: 12px;
    }
    span {
      position: absolute;
      left: 40px;
      top: 2px;
      font-size: 14px;
    }
    input {
      border: none;
      outline: none;
      height: 100%;
      width: 100%;
      font-size: 18px;
    }
  }
  #buttons {
    width: 100%;
    margin-top: 50px;
    display: flex;
    justify-content: space-around;
    button {
      font-family: Work Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
      order: 0;
      align-self: center;
      padding: 10px 20px 10px 20px;
      background: #6166ea;
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04),
        0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
      border-radius: 20px;
      border: none;
      &:hover {
        cursor: pointer;
        opacity: 0.9;
      }
    }
    #cancel {
      background: #3a3a3a;
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04),
        0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
    }
  }
}

@media only screen and (max-width: 1080px) {
  #data-wrapper {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    overflow-y: auto;
    grid-template-rows: auto auto;
    grid-auto-rows: auto;
    grid-template-columns: 100%;
    grid-template-areas: none;
    grid-gap: 20px;
    max-height: 100%;
    section {
      margin: 0;
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        h2 {
          color: #0a2540 !important;
        }
      }
      &:last-child {
        margin-bottom: 30px;
      }
    }
  }
}
</style>
