<template>
  <div>
    <template v-if="!forgotEmailSend">
      <p id="reset-text">
        Enter your E-Mail below and we'll send you a link to reset your
        password.
      </p>
      <label>E-MAIL</label>
      <FancyInput
        type="text"
        name="email"
        :value="email"
        @input="email = $event"
        placeholder="mail@example.com"
        width="70%"
        tabindex="1"
        @enter="trySubmit"
      />
      <div id="form-buttons">
        <router-link
          tag="button"
          id="left-btn"
          :to="{ name: 'LoginForm', params: { email } }"
          tabindex="2"
        >
          Sign in instead
        </router-link>
        <button id="right-btn" @click="reset" tabindex="3">
          {{ loading ? "" : "Reset" }}
          <Spinner color="#ffffff" class="spinner" v-if="loading" />
        </button>
      </div>
    </template>
    <template v-else>
      <div id="reset-success">
        We have sent a reset link to your email, it should be there soon!
        <router-link
          id="center-btn"
          :to="{ name: 'LoginForm', params: { email } }"
          tag="button"
          >Okay</router-link
        >
      </div>
    </template>
  </div>
</template>

<script>
import FancyInput from "@/components/FancyInput.vue";
import Spinner from "@/components/Spinner.vue";
export default {
  name: "Login",
  components: {
    FancyInput,
    Spinner,
  },
  data() {
    return {
      email: this.$route.params.email ?? "",
      loading: false,
      forgotEmailSend: false,
    };
  },
  methods: {
    trySubmit() {
      if (this.email && !this.loading) this.reset();
      else this.$emit("error", "Invalid E-Mail");
    },
    reset() {
      this.$emit("error", "");
      this.loading = true;

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: this.email,
        }),
        redirect: "follow",
        credentials: "include",
      };
      fetch(`${process.env.VUE_APP_BASE_URL}/auth/resetpw`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
          this.loading = false;
          if (result.success) {
            this.forgotEmailSend = true;
            this.email = "";
          } else {
            this.$emit("error", result.msg);
          }
        });
    },
  },
};
</script>

<style scoped lang="scss">
* {
  margin-bottom: 10px;
}

.spinner {
  width: 12px !important;
  height: 12px !important;
  min-width: 12px !important;
  min-height: 12px !important;
  border-width: 5px !important;
}

label {
  font-family: Work Sans;
  font-weight: bold;
  margin-right: auto;
  margin-left: 15%;
  color: #0a2540;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 5px;
  letter-spacing: 0.1em;
}

#reset-text {
  width: 70%;
  margin-bottom: 20px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.05em;
  color: #000000;
  text-align: left;
}

#reset-success {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 40px 30px 0 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#center-btn {
  margin-top: 50px;
}
</style>
