<template>
  <div id="header">
    <img src="/img/logoWhite.svg" @click="goToDashboard()" />
    <div id="upgrade">
      <router-link id="upgrade-btn" :to="{ name: 'Subscribe1' }" v-if="!isPro"
        >Upgrade to PRO</router-link
      >
    </div>
    <div id="language" @click="currenciesOpen = !currenciesOpen" v-if="value">
      <span id="current">
        {{ `${name} ${sign}` }}
        <img
          src="/img/icons/fancyChevron.svg"
          :style="{ transform: currenciesOpen ? 'rotate(180deg)' : '' }"
        />
      </span>
      <div id="options" v-if="currenciesOpen">
        <div
          v-for="item in currencies"
          :key="item.data"
          :style="{
            backgroundColor:
              value === item.data ? 'rgba(58, 58, 58, 0.2)' : 'transparent',
          }"
          @click="changeCurrency(item.data)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <router-link to="/account" v-if="route === 'Dashboard'" id="link"
      >Account</router-link
    >
    <router-link to="/" v-if="route === 'Account'" id="link"
      >Dashboard</router-link
    >
    <a href="#" @click="logout" id="logout">Logout</a>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from "vuex";
export default {
  name: "Header",
  props: {
    value: String,
  },
  components: {},
  data() {
    return {
      currencies: [
        {
          data: "EUR",
          name: "Euro",
          sign: "€",
        },
        {
          data: "USD",
          name: "US Dollar",
          sign: "$",
        },
        {
          data: "GBP",
          name: "British Pounds",
          sign: "£",
        },
        {
          data: "CAD",
          name: "CA Dollar",
          sign: "CA$",
        },
        {
          data: "AUD",
          name: "AU Dollar",
          sign: "A$",
        },
      ],
      currenciesOpen: false,
    };
  },
  methods: {
    changeCurrency(data) {
      this.$emit("input", data);
    },
    goToDashboard() {
      this.$router.push("/");
    },
    logout() {
      var requestOptions = {
        method: "POST",
        redirect: "follow",
        credentials: "include",
      };
      fetch(`${process.env.VUE_APP_BASE_URL}/auth/signout`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.success) {
            this.$router.push("/login");
          } else {
            alert("Something went wrong");
          }
        });
    },
  },
  computed: {
    ...mapState({
      isPro: (state) => state.isPro,
    }),
    name() {
      for (let i = 0; i < this.currencies.length; i++)
        if (this.currencies[i].data === this.value)
          return this.currencies[i].name;
      return "error";
    },
    route() {
      return this.$route.name;
    },
    sign() {
      for (let i = 0; i < this.currencies.length; i++)
        if (this.currencies[i].data === this.value)
          return this.currencies[i].sign;
      return "error";
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
#header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 75px;
  img {
    position: absolute;
    height: 40%;
    left: 20px;
    top: 30%;
    max-width: 25%;
    &:hover {
      cursor: pointer;
    }
  }
  #link {
    position: absolute;
    height: 100%;
    right: 80px;
    top: 0;
    display: flex;
    align-items: center;
    color: #ffffff;
    font-family: Work Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-decoration: none;
    &:visited {
      color: white;
    }
  }
  #logout {
    position: absolute;
    height: 100%;
    right: 0px;
    top: 0;
    display: flex;
    align-items: center;
    color: #ffffff;
    font-family: Work Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-decoration: none;
    &:visited {
      color: white;
    }
  }
  #language {
    align-self: flex-end;
    position: absolute;
    top: 0;
    right: 300px;
    height: 100%;
    display: flex;
    align-items: center;
    color: white;
    user-select: none;
    &:hover {
      cursor: pointer;
    }
    #current {
      font-weight: bold;
      img {
        position: relative;
        top: 3px;
        left: 0;
      }
    }
    #options {
      color: black;
      position: absolute;
      top: 65%;
      right: 0;
      width: fit-content;
      background: #ffffff;
      opacity: 0.75;
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04),
        0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-top: 20px;
      padding-bottom: 20px;
      z-index: 70;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      color: #3a3a3a;
      div {
        padding: 5px 20px 5px 20px;
        width: 100%;
        box-sizing: border-box;
        text-align: left;
        white-space: nowrap;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  #upgrade {
    position: absolute;
    height: 100%;
    left: 26%;
    top: 0;
    display: flex;
    align-items: center;

    a#upgrade-btn {
      float: none;
      font-family: Work Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      color: #ff762b;
      width: auto;
      flex: none;
      order: 0;
      align-self: center;
      flex-grow: 0;
      margin: 0 30px 0 0;
      background: #f6f9fc;
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.12);
      border-radius: 20px;
      padding: 10px 20px 10px 20px;
      text-decoration: none;
      transition: box-shadow 0.1s linear;
      &:hover {
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
        cursor: pointer;
      }
    }
  }
}

@media only screen and (max-width: 1080px) {
  #header {
    #language {
      top: 30px !important;
      width: 100% !important;
      right: 0 !important;
      display: flex !important;
      justify-content: center !important;
      #options {
        right: 30% !important;
        opacity: 1 !important;
      }
    }
    img {
      max-width: 40%;
    }
  }
}
</style>
