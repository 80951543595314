<template>
  <div v-if="visible">
    <button @click="accept">
      <img src="/img/icons/circledX.svg" />
    </button>
    <span
      >The MerchCosmos Dashboard uses cookies. By using MerchCosmos, you agree
      with our cookie usage.</span
    >
  </div>
</template>

<script>
export default {
  name: "Cookies",
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    accept() {
      localStorage.setItem("cookiesAccepted", true);
      this.visible = false;
    },
  },
  mounted() {
    if (!localStorage.getItem("cookiesAccepted")) {
      this.visible = true;
    }
  },
};
</script>

<style scoped lang="scss">
div {
  position: fixed;
  bottom: 20px;
  left: 25%;
  width: 50%;
  display: flex;
  padding: 30px 50px 30px 50px;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
  background: #ffffff;
  box-shadow: 0px 12px 50px rgba(60, 60, 86, 0.4),
    5px 20px 20px rgba(60, 60, 86, 0.25);
  border-radius: 10px;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  letter-spacing: 0.2px;

  color: #000000;
  button {
    border: none;
    background-color: #ffffff;
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
