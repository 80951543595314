<template>
  <div id="app">
    <vue-particles
      color="#dedede"
      :particleOpacity="0.7"
      :particlesNumber="50"
      shapeType="circle"
      :particleSize="2"
      linesColor="#dedede"
      :linesWidth="1"
      :lineLinked="true"
      :lineOpacity="0.4"
      :linesDistance="150"
      :moveSpeed="1"
      :hoverEffect="true"
      hoverMode="grab"
      :clickEffect="false"
    ></vue-particles>
    <router-view />
    <Cookies />
  </div>
</template>

<script>
import Cookies from "@/components/Cookies.vue";
export default {
  name: "App",
  components: {
    Cookies,
  },
  mounted() {
    this.$store.dispatch("checkUserPro");
  },
};
</script>

<style lang="scss">
#app {
  height: 100%;
  width: 100%;
  font-family: "Montserrat", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  position: relative;
  &::after {
    z-index: -1;
    content: "";
    width: 100%;
    height: 100%;
    background-image: url("/img/gradient2.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    position: fixed;
    top: 0;
    left: 0;
  }
}

#particles-js {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
}

html {
  height: 100%;
  width: 100%;
}

input {
  font-family: "Montserrat", sans-serif !important;
}

button {
  font-family: "Montserrat", sans-serif;
}

body {
  height: 100%;
  width: 100%;
  margin: 0;
  position: fixed;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
