<template>
  <div>
    <label>PASSWORD</label>
    <FancyInput
      type="password"
      name="password"
      v-model="password"
      placeholder="enter your password..."
      width="70%"
      tabindex="1"
      @enter="trySubmit"
    />
    <label>RE-ENTER PASSWORD</label>
    <FancyInput
      type="password"
      name="confirmPassword"
      v-model="confirmPassword"
      placeholder="repeat your password..."
      width="70%"
      tabindex="2"
      @enter="trySubmit"
    />
    <div id="form-buttons">
      <router-link tag="button" id="left-btn" to="/login" tabindex="3">
        Sign in instead
      </router-link>
      <button id="right-btn" @click="updatePassword" tabindex="4">
        {{ loading ? "" : "Update" }}
        <Spinner color="#ffffff" class="spinner" v-if="loading" />
      </button>
    </div>
  </div>
</template>

<script>
import FancyInput from "@/components/FancyInput.vue";
import Spinner from "@/components/Spinner.vue";
export default {
  name: "Login",
  components: {
    FancyInput,
    Spinner,
  },
  data() {
    return {
      password: "",
      confirmPassword: "",
      loading: false,
      resetId: "",
    };
  },
  methods: {
    trySubmit() {
      if (this.password && this.confirmPassword) this.updatePassword();
      else this.$emit("error", "Enter your new Password & confirm it");
    },
    updatePassword() {
      this.$emit("error", "");
      this.loading = true;

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          newPassword1: this.password,
          newPassword2: this.confirmPassword,
        }),
        redirect: "follow",
        credentials: "include",
      };
      fetch(
        `${process.env.VUE_APP_BASE_URL}/auth/resetpw/${this.resetId}`,
        requestOptions
      )
        .then((res) => res.json())
        .then((result) => {
          this.loading = false;
          if (result.success) this.$router.push("/login");
          else this.$emit("error", result.msg);
        });
    },
  },
  mounted() {
    if (
      window.location.hash.length > 1 &&
      window.location.hash.startsWith("#id=")
    ) {
      this.resetId = window.location.hash.replace("#id=", "");
    } else this.$router.push("/login");
  },
};
</script>

<style scoped lang="scss">
* {
  margin-bottom: 10px;
}

.spinner {
  width: 12px !important;
  height: 12px !important;
  min-width: 12px !important;
  min-height: 12px !important;
  border-width: 5px !important;
}

label {
  font-family: Work Sans;
  font-weight: bold;
  margin-right: auto;
  margin-left: 15%;
  color: #0a2540;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 5px;
  letter-spacing: 0.1em;
}

a {
  color: #6166ea;
  letter-spacing: 0.05em;
  font-size: 14px;
  margin: 0;
  margin-bottom: 100px;
}

a {
  float: left;
  margin-left: 15%;
  margin-top: 7px;
  text-decoration: none;
  color: #ddd;
}

#reset-text {
  width: 70%;
  margin-bottom: 20px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.05em;
  color: #000000;
  text-align: left;
}

#reset-success {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 50px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  button {
    float: none !important;
    margin: 0;
    margin-top: 50px;
  }
}
</style>
