<template>
  <div v-bind:class="{ disabled: this.disabled }">
    <img :src="img" id="icon" />
    <label>{{ name }}</label>
    <div id="value" :name="name" :tabindex="tabindex">
      <div
        :style="{ fontWeight: value !== null && !value ? 'bold' : 'normal' }"
        @click="emit('input', false)"
      >
        {{ option1 }}
      </div>
      <div
        :style="{ fontWeight: value !== null && value ? 'bold' : 'normal' }"
        @click="emit('input', true)"
      >
        {{ option2 }}
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "AccountSwitch",
  props: {
    type: String,
    name: String,
    value: { type: Boolean, default: null },
    option1: String,
    option2: String,
    tabindex: String,
    img: String,
    disabled: { required: false, default: false, type: Boolean },
  },
  components: {},
  data() {
    return {};
  },
  methods: {
    emit(key, value) {
      if (this.disabled) return;
      this.$emit(key, value);
    },
  },
  computed: {},
};
</script>

<style scoped lang="scss">
div.disabled #value {
  background-color: rgba(180, 180, 180, 0.3);
}

div {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin-bottom: 20px;

  #icon {
    width: 12px;
    position: absolute;
    left: 15px;
    top: 6px;
  }
  #edit {
    position: absolute;
    right: 15px;
    top: 12px;
    &:hover {
      cursor: pointer;
    }
  }
  label {
    color: black;
    position: absolute;
    left: 35px;
    top: 5px;
    padding-left: 2px;
    padding-right: 2px;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    pointer-events: none;
  }
}
#value {
  box-sizing: border-box;
  border: 1px solid rgba(66, 84, 102, 0.2);
  border-radius: 5px;
  margin: 0;
  padding: 8px;
  padding-top: 27px;
  padding-bottom: 16px;
  white-space: normal;
  background: none;
  line-height: 1;

  text-align: left;
  /* Browsers have different default form fonts */
  font-family: "Montserrat", sans-serif;
  width: 100%;
  height: 100%;
  font-size: 14px;
  font-weight: bold;
  height: 34px;
  border-radius: 5px;
  letter-spacing: 0.05em;

  display: flex;
  flex-direction: row;
  div {
    position: static;
    width: 50%;
    text-align: center;
    margin-top: 10px;
    display: block;
    margin-bottom: 0;
    &:hover {
      cursor: pointer;
    }
    &:first-child {
      border-right: solid 1px black;
    }
  }

  &::placeholder {
    font-weight: normal;
  }
}
</style>
