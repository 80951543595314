<script>
import { Bar } from "vue-chartjs";
export default {
  extends: Bar,
  name: "BarChart",
  props: { data: Object, options: Object },
  mounted() {
    this.renderChart(this.data, this.options);
  },
};
</script>

<style scoped lang="scss"></style>
