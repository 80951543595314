<template>
  <div id="wrapper">
    <div id="first-signup-message" v-if="firstSignup">HELLO WORLD :)</div>
    <Header v-model="currency"></Header>
    <div id="left-part">
      <div class="box-header">
        <h2>Sales Overview</h2>
        <p>
          <!--Last updated today, 14:03-->
        </p>
      </div>
      <div id="body" class="custom-scroll">
        <div class="all-time-stat">
          <h2>Total sales</h2>
          <div>Total recorded sales on every platform</div>
          <h1 v-if="loaded">{{ allTimeSales }}</h1>
          <div class="spinner-wrapper" v-else>
            <Spinner
              color="orange"
              size="47px"
              :style="{ marginTop: 0 }"
            ></Spinner>
          </div>
        </div>
        <div class="all-time-stat">
          <h2>Total profit</h2>
          <div>Total profit on every platform</div>
          <h1 v-if="loaded">{{ displayProfit }}</h1>
          <div class="spinner-wrapper" v-else>
            <Spinner
              color="orange"
              size="47px"
              :style="{ marginTop: 0 }"
            ></Spinner>
          </div>
        </div>
        <div class="all-time-stat">
          <h2>{{ timeFrameName }} sales</h2>
          <div>
            Total sales on {{ platformDescription }} in
            {{ timeFrameNameDescription }}
          </div>
          <h1 v-if="loaded" :class="{ blurred: !showChart }">
            {{ currentSales.sales }}
          </h1>
          <div class="spinner-wrapper" v-else>
            <Spinner
              color="orange"
              size="47px"
              :style="{ marginTop: 0 }"
            ></Spinner>
          </div>
          <img src="/img/icons/locked.svg" v-if="!showChart" />
        </div>
        <div class="all-time-stat">
          <h2>{{ timeFrameName }} profit</h2>
          <div>
            Total profit on {{ platformDescription }} in
            {{ timeFrameNameDescription }}
          </div>
          <h1 v-if="loaded" :class="{ blurred: !showChart }">
            {{ currentSales.profit }}
          </h1>
          <div class="spinner-wrapper" v-else>
            <Spinner
              color="orange"
              size="47px"
              :style="{ marginTop: 0 }"
            ></Spinner>
          </div>
          <img src="/img/icons/locked.svg" v-if="!showChart" />
        </div>
      </div>
    </div>
    <div id="chart">
      <div id="chart-header">
        <div id="platform-selector">
          <PlatformSelector
            :value="selector"
            @input="platformChanged"
            :platforms="platforms"
          ></PlatformSelector>
        </div>
        <div id="time-selector" @click="timeSelectorOpen = !timeSelectorOpen">
          <span id="current">
            {{ time }}
            <img
              src="/img/icons/fancyChevron.svg"
              :style="{ transform: timeSelectorOpen ? 'rotate(180deg)' : '' }"
            />
          </span>
          <div id="options" v-if="timeSelectorOpen">
            <div
              :class="{ option: true, active: item === time }"
              v-for="item in timeOptions"
              :key="item"
              @click="timeChanged(item)"
            >
              {{ item }}
            </div>
          </div>
        </div>
      </div>
      <div id="mobile-chart-header">{{ selector }} {{ time }}</div>
      <div id="chart-body">
        <BarChart
          v-if="loaded"
          :data="data"
          :options="options"
          :styles="chartStyle"
          id="chart-data"
          :class="{ 'blurred-chart': !showChart }"
        ></BarChart>
        <div v-if="!loaded" id="bar-loading-spinner-wrapper">
          <Spinner color="orange" size="150px"></Spinner>
        </div>
        <div v-if="!showChart" id="no-pro-chart">
          <div id="left">
            <h1>THIS IS A PRO FEATURE</h1>
            <p>
              Analyze your historical sales data and more with a MerchCosmos PRO
              Plan.
            </p>
            <div id="buttons">
              <router-link to="/subscribe" id="pro">Upgrade to PRO</router-link>
              <a href="https://merchcosmos.com#first-info" id="benefits"
                >View benefits<img src="img/icons/chevronRight.svg"
              /></a>
            </div>
          </div>
          <img id="right" src="img/noChart.svg" />
        </div>
        <div id="no-data-chart" v-if="currentSales.sales === 0 && loaded">
          <img src="img/noData.svg" />
          <h3>Hmmm, nothing to see here...</h3>
          <p>
            You either did not make any sales in the selected period, or
            something went wrong fetching your data. Please install the
            MerchCosmos Chrome extension and log in to your account for the
            system to work properly. You can download and install the Chrome
            Extension <a :href="extensionLink">here.</a>
          </p>
        </div>
      </div>
    </div>

    <div id="bottom-part">
      <div id="colors">
        <h3>COLORS</h3>
        <div
          class="scroll-box custom-scroll"
          :class="{ 'blur-content': !isPro }"
          :style="{ overflow: isPro ? '' : 'hidden' }"
        >
          <div v-for="item in allTimeColors" :key="item.color">
            <div class="color-name">{{ item.color.replace("_", " ") }}</div>
            <div>{{ item.amount }}</div>
          </div>
          <div class="locked-pro" v-if="!isPro">
            <img src="/img/icons/locked.svg" />
            <router-link to="/subscribe">Upgrade to PRO</router-link>
          </div>
        </div>
      </div>
      <div id="top-sellers">
        <h3>TOP SELLERS</h3>
        <div
          class="scroll-box custom-scroll"
          :class="{ 'blur-content': !isPro }"
          :style="{ overflow: isPro ? '' : 'hidden' }"
        >
          <div
            class="sold-item"
            v-for="(item, index) in allTimeTopSellers"
            :key="index"
          >
            <img :src="getImageLink(item.image)" />
            <div>
              <a
                class="name link"
                :href="item.link"
                target="_blank"
                rel="noopener noreferrer"
                v-if="item.link !== 'link not found'"
                >{{ item.name }}</a
              >
              <div class="name" v-else>{{ item.name }}</div>
              <div class="profit">{{ applyCurrency(item.profit / 100) }}</div>
              <div class="amount">
                {{ item.amount }}
                <span>Sales</span>
              </div>
            </div>
          </div>
          <div class="locked-pro" v-if="!isPro">
            <img src="/img/icons/locked.svg" />
            <router-link to="/subscribe">Upgrade to PRO</router-link>
          </div>
        </div>
      </div>
      <div id="revenue-by-platform">
        <h3>SALES BY PLATFORM</h3>
        <div id="container">
          <div>
            <span>Amazon:</span>
            <h3 v-if="loaded">
              {{
                profitByPlatform === null
                  ? applyCurrency(0)
                  : applyCurrency(
                      ((profitByPlatform.mba_us || 0) +
                        (profitByPlatform.mba_de || 0) +
                        (profitByPlatform.mba_uk || 0)) /
                        100
                    )
              }}
            </h3>
            <Spinner
              v-else
              color="black"
              size="29px"
              :style="{ marginTop: 0 }"
            ></Spinner>
          </div>
          <div>
            <span>Spreadshirt EU:</span>
            <h3 v-if="loaded && profitByPlatform">
              {{ applyCurrency((this.profitByPlatform.spr_eu || 0) / 100) }}
            </h3>
            <Spinner
              v-else
              color="black"
              size="29px"
              :style="{ marginTop: 0 }"
            ></Spinner>
          </div>
          <div>
            <span>Spreadshirt NA:</span>
            <h3 v-if="loaded && profitByPlatform">
              {{ applyCurrency((this.profitByPlatform.spr_na || 0) / 100) }}
            </h3>
            <Spinner
              v-else
              color="black"
              size="29px"
              :style="{ marginTop: 0 }"
            ></Spinner>
          </div>
        </div>
      </div>
    </div>
    <div id="right-part">
      <div class="box-header">
        <h2>Recent Sales</h2>
        <p>
          <!--Last updated today, 14:03-->
        </p>
      </div>

      <div class="scroll-box custom-scroll" id="body">
        <div
          class="sold-item"
          v-for="item in thisMonthsSales"
          :key="item.uniquesaleid"
        >
          <img :src="getImageLink(item.image)" />
          <div>
            <a
              class="name"
              :href="item.link"
              target="_blank"
              rel="noopener noreferrer"
              v-if="item.link !== 'link not found'"
              >{{ item.name }}</a
            >
            <div class="name" v-else>{{ item.name }}</div>

            <div class="amount">{{ item.profit }}</div>
          </div>
        </div>
      </div>
    </div>
    <div id="footer-info">
      <div>MerchCosmos V 0.1.0</div>
      <div>© {{ new Date().getUTCFullYear() }} MERCHCOSMOS</div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import BarChart from "@/components/BarChart.vue";
import PlatformSelector from "@/components/PlatformSelector.vue";
import Spinner from "@/components/Spinner.vue";
import { mapState } from "vuex";
export default {
  name: "Dashboard",
  components: {
    Header,
    BarChart,
    PlatformSelector,
    Spinner,
  },
  props: {
    firstSignup: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    chartNoData() {
      return this.currentSales === 0;
    },
    showChart() {
      return this.isPro || this.time === "daily" || !this.loaded;
    },
    ...mapState({
      isPro: (state) => state.isPro,
    }),
    displayRevenue() {
      let revenue = this.allTimeRevenue / 100;
      return this.applyCurrency(revenue);
    },
    displayProfit() {
      let profit = this.allTimeProfit / 100;

      return this.applyCurrency(profit);
    },
    currentSales() {
      if (this.data === null) return { sales: null, profit: null };
      let totalSales = 0;
      let totalProfit = 0;
      for (let i = 0; i < this.data.datasets.length; i++) {
        let set = this.data.datasets[i];
        if (set.type === "line") {
          //dataset is profit data
          for (let j = 0; j < set.data.length; j++) {
            totalProfit += Number.parseFloat(set.data[j]);
          }
        } else {
          //dataset is sales data
          for (let j = 0; j < set.data.length; j++) {
            totalSales += set.data[j];
          }
        }
      }
      return { sales: totalSales, profit: this.applyCurrency(totalProfit) };
    },
    timeFrameName() {
      if (this.time === "daily") {
        return "Last 30 days";
      } else {
        return this.time;
      }
    },
    timeFrameNameDescription() {
      if (this.time === "daily") {
        return "the last 30 days";
      } else {
        return this.time;
      }
    },
    platformDescription() {
      switch (this.selector) {
        case "combined":
          return "every platform";
        case "mba":
          return "Merch by Amazon ";
        case "spr_eu":
          return "Spreadshirt EU";
        case "spr_na":
          return "Spreadshirt NA";
        default:
          return "every platform";
      }
    },
  },
  data() {
    return {
      time: "2020",
      timeSelectorOpen: false,
      timeOptions: ["daily", "2020", "2019", "2018", "2017"],
      selector: "combined",
      platforms: [
        { name: "Combined", data: "combined" },
        { name: "Amazon", data: "mba" },
        { name: "Spreadshirt EU", data: "spr_eu" },
        { name: "Spreadshirt NA", data: "spr_na" },
      ],
      allTimeSales: 0,
      allTimeRevenue: 0,
      allTimeProfit: 0,
      allTimeColors: [],
      allTimeTopSellers: [],
      thisMonthsSales: [],
      profitByPlatform: null,
      currency: "CAD",
      currencies: null,
      loaded: false,
      data: null,
      extensionLink: process.env.VUE_APP_EXTENSION_LINK,
      options: {
        maintainAspectRatio: false,
        responsive: true,
        legend: {
          display: false,
        },
        tooltips: {
          mode: "index",
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
              stacked: true,
            },
            {
              id: "profit",
              display: false,
              stacked: false,
              scaleLabel: {
                display: false,
                labelString: "Dollar Amount",
              },
              ticks: {
                beginAtZero: true,
              },
            },
          ],
          xAxes: [
            {
              stacked: true,
              gridLines: {
                color: "transparent",
              },
            },
          ],
        },
      },
      chartStyle: {
        height: "90%",
        width: "100%",
        position: "relative",
      },
    };
  },
  watch: {
    currency() {
      this.updateData();
      this.updateAdditionalData();
      localStorage.setItem("curr", this.currency);
    },
  },
  methods: {
    getImageLink(link) {
      if (link.includes("https://")) return link;
      else return `${process.env.VUE_APP_BASE_URL}/account/img?url=${link}`;
    },
    applyCurrency(number) {
      let suffix = "";
      if (number >= 1000000) {
        number = number / 1000000;
        suffix = "M";
      } else if (number >= 10000) {
        number = number / 1000;
        suffix = "k";
      }

      let curr = number.toLocaleString("en-US", {
        currency: this.currency,
        style: "currency",
      });

      if (this.currency === "EUR") {
        curr = curr.replace("€", "");
        return curr + suffix + "€";
      }
      return curr + suffix;
    },
    fetchCurrencies() {
      fetch(`${process.env.VUE_APP_BASE_URL}/curr`)
        .then((response) => response.json())
        .then((result) => {
          this.currencies = result.currencies.rates;
        });
    },
    decreaseDate(date) {
      let d = new Date(date);
      d.setUTCDate(d.getUTCDate() - 1);
      return d.toISOString().split("T")[0];
    },
    curr(profit, date) {
      if (this.currency === "EUR" || this.currencies === null) return profit;
      while (this.currencies[date] === undefined) {
        date = this.decreaseDate(date);
      }
      return (profit * this.currencies[date][this.currency]).toFixed(2);
    },
    fillZeros(number) {
      if (number < 10) return "0" + number;
      return number;
    },
    platformChanged(e) {
      if (this.selector !== e) {
        this.selector = e;
        this.updateData();
      }
    },
    timeChanged(newTime) {
      if (this.time !== newTime) {
        this.time = newTime;
        this.updateData();
      }
    },
    convertData(raw) {
      let data = { sales: [], profit: [] };

      let labels;
      if (this.time !== "daily") {
        labels = [
          "JAN",
          "FEB",
          "MAR",
          "APR",
          "MAY",
          "JUN",
          "JUL",
          "AUG",
          "SEP",
          "OCT",
          "NOV",
          "DEC",
        ];
        for (let i = 0; i < 12; i++) {
          let sales =
            raw[i + "." + this.time] !== undefined
              ? raw[i + "." + this.time].sales
              : 0;
          data.sales.push(sales);
          let profit =
            raw[i + "." + this.time] !== undefined
              ? raw[i + "." + this.time].profit
              : 0;
          data.profit.push(
            this.curr(profit / 100, `${this.time}-${this.fillZeros(i + 1)}-15`)
          );
        }
      } else {
        let today = new Date();
        let date =
          today.getUTCDate() +
          "." +
          today.getUTCMonth() +
          "." +
          today.getUTCFullYear();
        let sales = raw[date] !== undefined ? raw[date].sales : 0;
        data.sales.push(sales);
        let profit = raw[date] !== undefined ? raw[date].profit : 0;
        data.profit.push(
          this.curr(
            profit / 100,
            `${today.getUTCFullYear()}-${this.fillZeros(
              today.getUTCMonth()
            )}-${this.fillZeros(today.getUTCDate())}`
          )
        );
        labels = [today.getUTCDate()];

        for (let i = 0; i < 29; i++) {
          today.setUTCDate(today.getUTCDate() - 1);
          labels.push(today.getUTCDate());
          let date =
            today.getUTCDate() +
            "." +
            today.getUTCMonth() +
            "." +
            today.getUTCFullYear();
          let sales = raw[date] !== undefined ? raw[date].sales : 0;
          data.sales.push(sales);
          let profit = raw[date] !== undefined ? raw[date].profit : 0;
          data.profit.push(
            this.curr(
              profit / 100,
              `${today.getUTCFullYear()}-${this.fillZeros(
                today.getUTCMonth()
              )}-${this.fillZeros(today.getUTCDate())}`
            )
          );
        }
        labels.reverse();
        data.sales.reverse();
        data.profit.reverse();
      }
      return { data: data, labels: labels };
    },
    getColorForPlatform(platform) {
      switch (platform) {
        case "mba":
          return "255, 153, 0";
        case "mba_us":
          return "255, 153, 0";
        case "mba_de":
          return "255, 153, 0";
        case "mba_uk":
          return "255, 153, 0";
        case "spr_eu":
          return "0,178,165";
        case "spr_na":
          return "0,91,85";
      }
    },
    updateAdditionalData() {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow",
        credentials: "include",
      };
      fetch(
        `${process.env.VUE_APP_BASE_URL}/data/additional/${this.currency}`,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          let raw = JSON.parse(result);
          if (raw.success) {
            this.allTimeSales = raw.sales;
            this.allTimeRevenue = raw.revenue;
            this.allTimeProfit = raw.profit;
            let colors = [];
            let topSellers = [];
            Object.keys(raw.colors).forEach(function (key) {
              colors.push({ color: key, amount: raw.colors[key] });
            });
            colors.sort((a, b) => {
              return b.amount - a.amount;
            });
            this.allTimeColors = colors;

            Object.keys(raw.topSellers).forEach(function (key) {
              topSellers.push(raw.topSellers[key]);
            });
            topSellers.sort((a, b) => {
              return b.amount - a.amount;
            });
            let top20 = [];
            for (let i = 0; i < 20 && i < topSellers.length; i++) {
              top20.push(topSellers[i]);
            }
            this.allTimeTopSellers = top20;

            for (let i = 0; i < raw.thisMonth.length; i++) {
              let date = new Date(Number.parseInt(raw.thisMonth[i].timestamp));
              date = date.toISOString().split("T")[0];
              let currencyCorrected = this.curr(
                raw.thisMonth[i].profit / 100,
                date
              );
              raw.thisMonth[i].profit = this.applyCurrency(
                Number.parseFloat(currencyCorrected)
              );
            }

            this.thisMonthsSales = raw.thisMonth;

            this.profitByPlatform = raw.profitByPlatform;
          }
        });
    },
    updateData() {
      this.loaded = false;
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      urlencoded.append("time", this.time);
      urlencoded.append("platform", this.selector);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow",
        credentials: "include",
      };

      fetch(`${process.env.VUE_APP_BASE_URL}/data/get`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          let raw = JSON.parse(result);
          if (raw.success) {
            if (this.selector !== "combined") {
              let converted = this.convertData(raw.data);

              this.data = {
                labels: converted.labels,
                datasets: [
                  {
                    label: "Revenue",
                    data: converted.data.profit,
                    yAxisID: "profit",
                    type: "line",
                    borderColor: "#ff752c",
                    backgroundColor: "#ff752c33",
                    cubicInterpolationMode: "monotone",
                  },
                  {
                    label: "Sales",
                    data: converted.data.sales,
                    backgroundColor:
                      "rgba(" +
                      this.getColorForPlatform(this.selector) +
                      ", 1)",
                    borderColor:
                      "rgba(" +
                      this.getColorForPlatform(this.selector) +
                      ", 1)",
                    borderWidth: 1,
                  },
                ],
              };
            } else {
              let labels;
              let dataSets = [];
              let prices = [];
              Object.keys(raw.data).forEach((key) => {
                let converted = this.convertData(raw.data[key]);
                labels = converted.labels;
                if (prices.length === 0) prices = converted.data.profit;
                else {
                  for (let i = 0; i < converted.data.profit.length; i++) {
                    prices[i] =
                      Number.parseFloat(prices[i]) +
                      Number.parseFloat(converted.data.profit[i]);
                  }
                }
                let platformName;
                switch (key) {
                  case "mba_us":
                    platformName = "Merch by Amazon";
                    break;
                  case "spr_eu":
                    platformName = "Spreadshirt EU";
                    break;
                  case "spr_na":
                    platformName = "Spreadshirt NA";
                    break;
                }

                dataSets.push({
                  label: `${platformName}`,
                  data: converted.data.sales,
                  backgroundColor:
                    "rgba(" + this.getColorForPlatform(key) + ", 1)",
                  borderColor: "rgba(" + this.getColorForPlatform(key) + ", 1)",
                  borderWidth: 1,
                });
              });
              //add profit line

              dataSets.unshift({
                label: "Revenue",
                data: prices,
                yAxisID: "profit",
                type: "line",
                borderColor: "#ff752c",
                backgroundColor: "#ff752c33",
                cubicInterpolationMode: "monotone",
              });
              this.data = {
                labels: labels,
                datasets: dataSets,
              };
            }
            this.loaded = true;
          } else {
            if (
              raw.msg === "auth_invalid" ||
              raw.msg === "cookie_invalid" ||
              raw.msg === "token_expired"
            ) {
              this.$router.push("/login");
            }
          }
        })
        .catch((error) => new Error(error));
    },
  },
  mounted() {
    this.currency = localStorage.getItem("curr") || "EUR";
    this.fetchCurrencies();
    this.updateData();
    this.updateAdditionalData();
  },
};
</script>

<style scoped lang="scss">
#time-selector {
  position: relative;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 1px;
  text-transform: capitalize;
  color: #ffffff;
  &:hover {
    cursor: pointer;
  }
  #options {
    position: absolute;
    top: 100%;
    right: 0;
    width: auto;
    min-width: 140px;
    z-index: 50;
    background: #ffffff;
    opacity: 0.75;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04),
      0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 5px;
    overflow: hidden;
    .option {
      font-family: Work Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
      letter-spacing: 1px;
      color: #3a3a3a;
      padding-top: 2px;
      padding-bottom: 2px;
      text-transform: capitalize;
      &:hover {
        background: rgba(58, 58, 58, 0.2);
      }
    }
    .active {
      background: rgba(58, 58, 58, 0.2);
    }
  }
}
#footer-info {
  position: fixed;
  bottom: 30px;
  left: 25px;
  font-size: 10px;
  font-weight: 600;
  color: #828282;
  display: block;
  text-align: left;
}
#wrapper {
  font-family: Work Sans;
  box-sizing: border-box;
  width: calc(100% - 40px);
  height: calc(100% - 20px);
  padding-top: 100px; /*header spacing*/
  padding-left: 20px;
  padding-right: 20px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 50;
  display: grid;
  grid-template-columns: 20% 60% 20%;
  grid-template-rows: 65% 35%;
  grid-template-areas:
    "left-info  graph  right-info"
    "left-info  bottom-info  right-info";
  grid-gap: 20px;
  &::before {
    content: "";
    width: 100%;
    height: 50%;
    background-color: #f6f6f6;
    position: fixed;
    top: 50%;
    left: 0;
  }
}

.box-header {
  width: 100%;
  height: 60px;
  text-align: left;
  box-sizing: border-box;
  padding: 5px 25px 5px 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  h2 {
    font-size: 24px;
    line-height: 24px;
    color: #ffffff;
    margin: 0;
  }
  p {
    margin: 0;
    font-size: 12px;
    line-height: 15px;
    color: white;
  }
}

#left-part {
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 25px;
  grid-area: left-info;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-color: rgba(256, 256, 256, 0.28);
  box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04),
    0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 10px;

  #body {
    background-color: white;
    width: 100%;
    border-radius: 10px;
    height: calc(100% - 60px);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    .all-time-stat {
      border-bottom: 2px solid rgba(97, 102, 234, 0.1);
      box-sizing: border-box;
      padding: 15px 25px 15px 25px;
      position: relative;
      height: fit-content;
      margin-top: auto;
      margin-bottom: auto;
      &:last-child {
        border-bottom: none;
      }
      h2 {
        text-align: left;
        margin: 0;
        font-family: Work Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;

        color: #0a2540;
      }
      div {
        text-align: left;
        width: 100%;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;
        color: #425466;
      }
      h1 {
        font-family: Work Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 40px;
        line-height: 47px;
        color: #0a2540;
        display: flex;
        justify-content: center;
        margin: 0;
        margin-top: 2vh;
      }
      img {
        position: absolute;
        bottom: 40px;
        width: 36px;
        left: calc(50% - 18px);
      }
      .spinner-wrapper {
        height: 47px;
        margin-top: 26.8px;
        margin-bottom: 26.8px;
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

#chart {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  position: relative;
  z-index: 60;
  grid-area: graph;
  overflow: hidden;
  background-color: rgba(256, 256, 256, 0.28);
  box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04),
    0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
}
#chart-header {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
#mobile-chart-header {
  width: 100%;
  height: 60px;
  display: none;
}
#chart-body {
  background-color: white;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 20px;
  overflow: hidden;
  border-radius: 10px;
}

#right-part {
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 30px;
  grid-area: right-info;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(256, 256, 256, 0.28);
  box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04),
    0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  #body {
    box-sizing: border-box;
    background-color: white;
    width: 100%;
    border-radius: 10px;
    height: calc(100% - 60px);

    .sold-item {
      box-sizing: border-box;
      padding: 10px 10px 10px 20px;
      border-radius: 10px;
      width: 100%;
      background-color: white;
      color: #888;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      position: relative;
      margin-bottom: 20px;
      &::after {
        content: "";
        width: 100%;
        height: 0;
        border-bottom: 2px solid rgba(97, 102, 234, 0.1);
        position: absolute;
        top: calc(100% + 10px);
        left: 0;
      }

      &:first-child {
        margin-top: 10px;
      }
      &:last-child {
        margin-bottom: 10px;
        &::after {
          border-bottom: none;
        }
      }
      img {
        height: 80px;
        margin-top: 30px;
      }

      div {
        height: 80px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
        text-align: start;
        font-family: Work Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;

        /* text dunkel */

        color: #0a2540;
        a {
          color: #0a2540;
        }
        & :nth-child(2) {
          font-family: Work Sans;
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 21px;

          /* text dunkel */

          color: #0a2540;
        }
      }
    }
  }
}
#bottom-part {
  grid-area: bottom-info;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 100%;
  grid-template-areas: "color-info  best-sellers  revenue-info";
  position: relative;
  z-index: 60;
}
.scroll-box {
  overflow-y: auto;
}

#colors {
  grid-area: color-info;
  box-sizing: border-box;
  padding: 5px;
  border-radius: 10px;
  width: calc(100% - 10px);
  height: calc(100% - 30px);

  background-color: white;
  box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04),
    0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  & h3 {
    font-family: Work Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    margin: 0;
    color: #0a2540;
    margin-top: 10px;
    padding-bottom: 10px;
    text-align: left;
    border-bottom: 2px solid rgba(97, 102, 234, 0.1);
    box-sizing: border-box;
    padding-left: 10px;
  }
  & .scroll-box {
    padding-left: 10px;
    padding-top: 10px;
    box-sizing: border-box;
    max-height: calc(100% - 40px);
    & * {
      display: flex;
      margin-bottom: 2px;
      font-size: 20px;
      & :first-child {
        width: 70%;
        color: #425466;
        font-size: 14px;
        line-height: 17px;
      }
      & :nth-child(2) {
        width: 30%;
        font-weight: 700;
        justify-content: flex-end;
        box-sizing: border-box;
        padding-right: 10px;
        font-size: 14px;
        line-height: 17px;

        /* textfarbe */

        color: #425466;
      }
    }
  }
}

#top-sellers {
  border-radius: 10px;
  overflow: hidden;
  box-sizing: border-box;
  padding: 5px;
  grid-area: best-sellers;
  width: calc(100% - 20px);
  height: calc(100% - 30px);
  margin-left: 5px;
  background-color: white;
  box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04),
    0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  & h3 {
    font-family: Work Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    margin: 0;
    color: #0a2540;
    margin-top: 10px;
    padding-bottom: 10px;
    text-align: left;
    border-bottom: 2px solid rgba(97, 102, 234, 0.1);
    box-sizing: border-box;
    padding-left: 10px;
  }
  & .scroll-box {
    padding-left: 10px;
    padding-top: 10px;
    box-sizing: border-box;
    max-height: calc(100% - 40px);
    & .sold-item {
      width: calc(100% - 10px);
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      position: relative;
      border-bottom: 2px solid rgba(97, 102, 234, 0.1);
      div {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 100%;
        font-size: 12px;
        text-align: start;
        .name {
          font-family: Montserrat;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 17px;
          color: #425466;
        }
        .profit {
          font-family: Montserrat;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 17px;

          margin-top: 10px;
          margin-bottom: 5px;

          color: #425466;
        }
        .amount {
          font-family: Montserrat;
          font-style: normal;
          font-weight: bold;
          font-size: 20px;
          line-height: 24px;
          color: #0a2540;
          display: block;
          margin-bottom: 10px;
          span {
            font-size: 14px;
          }
        }
      }
      & img {
        width: 80px;
      }
      margin-right: 5px;
      margin-left: 5px;
      &:last-child {
        border-bottom: none;
      }
    }
  }
}

#revenue-by-platform {
  width: 100%;
  height: calc(100% - 30px);
  border-radius: 10px;
  padding: 5px;
  box-sizing: border-box;
  background-color: white;
  box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04),
    0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  & h3 {
    font-family: Work Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    margin: 0;
    color: #0a2540;
    margin-top: 10px;
    padding-bottom: 10px;
    text-align: left;
    border-bottom: 2px solid rgba(97, 102, 234, 0.1);
    box-sizing: border-box;
    padding-left: 10px;
  }
  grid-area: revenue-info;

  & #container {
    height: calc(100% - 36px);
    box-sizing: border-box;
    padding-left: 5%;
    padding-right: 5%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    & div {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      padding: 0;
      h3 {
        border: none;
      }
    }
  }
}

#platform-selector {
  width: 80%;
}

.custom-scroll {
  scrollbar-width: thin;
  scrollbar-color: #dddddd transparent;
  position: relative;

  /* Works on Chrome/Edge/Safari */
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 3px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #dddddd;
    border-radius: 3px;
  }
}
#bar-loading-spinner-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
#last-sales {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
}

#first-signup-message {
  position: fixed;
  top: 10vh;
  width: 80vw;
  left: 10vh;
  min-height: 30vh;
}
.color-name {
  text-transform: capitalize;
}

.locked-pro {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 100%;
  height: 100%;
  filter: blur(0) !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  img {
    height: 20%;
    width: auto !important;
    margin-bottom: 30px !important;
  }
  a {
    font-family: Work Sans;
    font-style: normal;
    font-weight: bold !important;
    font-size: 14px;
    line-height: 16px;
    color: #ff762b !important;
    width: auto !important;
    flex: none;
    order: 0;
    align-self: center;
    flex-grow: 0;
    margin: 10px 0px 0px 0px !important;
    background: #f6f9fc;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.12);
    border-radius: 20px;
    padding: 10px 20px 10px 20px !important;
    text-decoration: none;
    transition: box-shadow 0.1s linear;
    &:hover {
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    }
  }
}
.blur-content {
  div {
    filter: blur(5px);
  }
}

#no-pro-chart {
  position: absolute;
  left: 0;
  top: 60px;
  width: 100%;
  height: calc(100% - 60px);
  background-color: rgba(256, 256, 256, 0.9);
  border-radius: 10px;
  display: flex;
  #left {
    width: 50%;
    box-sizing: border-box;
    padding-left: 80px;
    padding-right: 20px;
    h1 {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 800;
      font-size: 48px;
      line-height: 140.4%;
      display: flex;
      align-items: center;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #0a2540;
      text-align: left;
      margin-bottom: 0;
    }
    p {
      font-family: Work Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #425466;
      text-align: left;
      margin-bottom: 50px;
    }
    #buttons {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      #pro {
        font-family: Work Sans;
        font-style: normal;
        font-weight: bold !important;
        font-size: 14px;
        line-height: 16px;
        color: #ff762b !important;
        width: auto !important;
        flex: none;
        order: 0;
        align-self: center;
        flex-grow: 0;
        margin-right: 30px;
        background: #f6f9fc;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.12);
        border-radius: 20px;
        padding: 10px 20px 10px 20px !important;
        text-decoration: none;
        transition: box-shadow 0.1s linear;
        &:hover {
          box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
        }
      }
      #benefits {
        font-family: Work Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: #0a2540;
        text-decoration: none;
        img {
          position: relative;
          top: 1px;
          left: 5px;
          transition: left 0.1s linear;
        }
        &:hover img {
          left: 10px;
        }
      }
    }
  }
}

#no-data-chart {
  position: absolute;
  left: 0;
  top: 60px;
  width: 100%;
  height: calc(100% - 60px);
  background-color: rgba(256, 256, 256, 0.8);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  padding-top: 50px;
  img {
  }
  h3 {
    font-family: Work Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #0a2540;
  }
  p {
    font-family: Work Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #0a2540;
    margin-left: 20%;
    margin-right: 20%;
    text-align: left;
    a {
      color: #ff762b;
      text-decoration: underline;
      font-weight: bold;
    }
  }
}

.blurred-chart {
  filter: blur(8px);
}

.blurred {
  filter: blur(10px);
  color: #bbb !important;
}

@media only screen and (max-width: 1080px) {
  #wrapper {
    overflow-y: auto;
    width: 100% !important;
    grid-template-columns: 100% !important;
    grid-template-rows: 300px auto 500px 1200px !important;
    grid-template-areas:
      "graph"
      "left-info"
      "right-info"
      "bottom-info";
  }
  #chart {
    #chart-header {
      display: none;
    }
    #mobile-chart-header {
      display: flex;
      font-size: 32px;
      font-weight: bold;
      text-transform: capitalize;
      color: #0a2540;
      justify-content: center;
      align-items: center;
    }
    #chart-body {
      padding-bottom: 35px;
    }
  }
  .box-header h2 {
    color: #0a2540 !important;
  }
  #bottom-part {
    grid-template-columns: 100%;
    grid-template-rows: auto 4fr 3fr;
    grid-template-areas:
      "color-info"
      "best-sellers"
      "revenue-info";
    grid-gap: 20px;
    #colors {
      height: 100%;
      width: 100%;
      margin: 0;
    }
    #top-sellers {
      width: 100%;
      margin: 0;
    }
    #revenue-per-platform {
      width: 100%;
      margin: 0;
    }
  }
}
</style>
