import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userData: {
      subscriptionPlan: "",
      firstname: "",
      lastname: "",
      email: "",
      street: "",
      zipcode: "",
      city: "",
      country: "",
      isCompany: null,
      companyName: "",
      importantEmails: false,
      newsletter: false,
      isAffiliate: false,
      totalReferred: 0,
      cashAllTime: 0,
      cashLastPayout: 0,
    },
    paymentMethods: {
      creditCards: [],
      defaultPayment: null,
      paypalAccounts: [],
    },
    isPro: false,
  },
  mutations: {
    updateUserDataProp(state, payload) {
      state.userData[payload.prop] = payload.value;
    },
    updatePro(state, isPro) {
      state.isPro = isPro;
    },
    updatePaymentMethods(state, data) {
      state.paymentMethods = data;
    },
  },
  actions: {
    checkUserPro() {
      fetch(`${process.env.VUE_APP_BASE_URL}/account/subscription`, {
        credentials: "include",
      })
        .then((data) => data.json())
        .then((data) => {
          this.commit("updatePro", data.success && data.subscribed);
        })
        .catch((err) => console.log(err));
    },
    sendUserData() {
      fetch(`${process.env.VUE_APP_BASE_URL}/account`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(this.state.userData),
        redirect: "follow",
        credentials: "include",
      })
        .then((res) => res.json())
        .then(() => {})
        .catch(() => {});
    },
    fetchPaymentMethod() {
      fetch(`${process.env.VUE_APP_BASE_URL}/account/paymentmethod`, {
        method: "GET",
        redirect: "follow",
        credentials: "include",
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.success) {
            delete result.success;
            this.commit("updatePaymentMethods", result);
          }
        });
    },
    fetchUserData() {
      fetch(`${process.env.VUE_APP_BASE_URL}/account`, {
        method: "GET",
        redirect: "follow",
        credentials: "include",
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.success) {
            result.customer.isCompany = result.customer.is_company === 1;
            result.customer.companyName = result.customer.company_name;
            result.customer.isAffiliate = result.customer.is_affiliate === 1;
            result.customer.totalReferred = result.customer.total_referred;
            result.customer.cashAllTime = result.customer.cash_all_time;
            result.customer.cashLastPayout = result.customer.cash_last_payout;
            delete result.customer.is_company;
            delete result.customer.company_name;
            delete result.customer.is_affiliate;
            delete result.customer.total_referred;
            delete result.customer.cash_all_time;
            delete result.customer.cash_last_payout;
            Object.keys(result.customer).forEach((key) => {
              this.commit("updateUserDataProp", {
                prop: key,
                value:
                  result.customer[key] === null ? "" : result.customer[key],
              });
            });
          } else {
            if (result.msg === "auth_invalid") this.$router.push("/login");
          }
        });
    },
  },
  modules: {},
});
