<template>
  <div :style="{ width: width }">
    <input
      :type="type"
      :name="name"
      :class="active ? 'input-active' : ''"
      :placeholder="placeholder"
      @focus="focused = true"
      @blur="focused = false"
      @keydown.enter="$emit('enter')"
      v-bind:value="value"
      v-on:input="$emit('input', $event.target.value)"
      :tabindex="tabindex"
    />
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "FancyInput",
  props: {
    type: String,
    name: String,
    value: String,
    placeholder: String,
    width: String,
    tabindex: String,
  },
  data() {
    return {
      focused: false,
    };
  },
  computed: {
    active() {
      return this.focused || this.value.length > 0;
    },
  },
};
</script>

<style scoped lang="scss">
input:focus + #icon-wrapper {
  background-color: #ff752c !important;
  color: #ff752c !important;
}
div {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
input {
  box-sizing: border-box;
  margin: 0;
  border: none;
  padding: 8px;
  padding-left: 15px;
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
  background: none;
  line-height: 1;
  font-family: "Montserrat", sans-serif;
  background-color: #f6f6f6;
  width: 100%;
  font-size: 12px;
  height: 34px;
  border-radius: 5px;
  letter-spacing: 0.05em;
}

label {
  color: white;
  position: absolute;
  left: 15px;
  top: 5px;
  padding-left: 2px;
  padding-right: 2px;
  font-size: 22px;
  transition: top 0.1s ease-in-out;
  transition: font-size 0.1s ease-in-out;
  pointer-events: none;
}

.input-active {
  outline: 0;
  & + label {
    top: -8px;
    font-size: 16px;
  }
}
</style>
