<template>
  <div>
    <label>E-MAIL</label>
    <FancyInput
      type="text"
      name="email"
      :value="email"
      @input="email = $event"
      placeholder="mail@example.com"
      width="70%"
      tabindex="1"
      @enter="trySubmit"
    />
    <label>PASSWORD</label>
    <FancyInput
      type="password"
      name="password"
      v-model="password"
      placeholder="enter your password..."
      width="70%"
      tabindex="2"
      @enter="trySubmit"
    />
    <router-link
      :to="{
        name: 'ForgotPasswordForm',
        params: { email },
      }"
      id="forgot"
      tabindex="3"
      tag="a"
      >forgot your password?</router-link
    >
    <div id="form-buttons">
      <router-link
        id="left-btn"
        tabindex="4"
        tag="button"
        :to="{ name: 'SignupForm', params: { email } }"
        >Sign up instead</router-link
      >
      <button id="right-btn" @click="signIn" tabindex="5">
        {{ loading ? "" : "Sign in" }}
        <Spinner color="#ffffff" class="spinner" v-if="loading" />
      </button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import FancyInput from "@/components/FancyInput.vue";
import Spinner from "@/components/Spinner.vue";
export default {
  name: "Login",
  components: {
    FancyInput,
    Spinner,
  },
  props: {
    title: {
      required: false,
      type: String,
      default: "LOGIN",
    },
  },
  computed: {
    s_title: function () {
      return this.title;
    },
  },
  data() {
    return {
      email: this.$route.params.email ?? "",
      password: "",
      loading: false,
    };
  },
  methods: {
    trySubmit() {
      if (this.email && this.password) this.signIn();
      else this.$emit("error", "Enter E-Mail & Password");
    },
    signIn() {
      this.$emit("error", "");
      this.loading = true;

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: this.email, password: this.password }),
        redirect: "follow",
        credentials: "include",
      };
      fetch(`${process.env.VUE_APP_BASE_URL}/auth/signin`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
          if (result.success) {
            this.$store.dispatch("checkUserPro");
            this.loading = false;
            this.$router.push("/");
          } else {
            this.$emit("error", result.msg);
            this.loading = false;
            this.password = "";
          }
        });
    },
  },
};
</script>

<style scoped lang="scss">
* {
  margin-bottom: 10px;
}

.spinner {
  width: 12px !important;
  height: 12px !important;
  min-width: 12px !important;
  min-height: 12px !important;
  border-width: 5px !important;
}

#forgot {
  width: 85%;
  text-align: left;
  margin-left: 15%;
  margin-right: 0;
  margin-bottom: 20px;
  margin-top: 10px;
  font-weight: bold;
  font-size: 13px;
  letter-spacing: 0.1em;
  text-decoration-line: underline;
  color: #606060;
}

label {
  font-family: Work Sans;
  font-weight: bold;
  margin-right: auto;
  margin-left: 15%;
  color: #0a2540;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 5px;
  letter-spacing: 0.1em;
}
</style>
