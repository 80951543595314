<template>
  <div>
    <img :src="img" id="icon" />
    <label>{{ name }}</label>
    <div id="value" :name="name" :tabindex="tabindex">{{ current }}</div>
    <img
      id="edit"
      src="/img/icons/chevron.svg"
      @click="active = !active"
      :style="{ transform: active ? 'rotate(180deg)' : '' }"
    />
    <div id="popup" v-if="active">
      <div
        class="country"
        v-for="item in options"
        :key="item.data"
        @click="changeValue(item.data)"
      >
        {{ item.text }}
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "AccountDropDown",
  props: {
    name: String,
    value: String,
    options: Array,
    tabindex: String,
    img: String,
    placeholder: String,
  },
  components: {},
  data() {
    return {
      active: false,
    };
  },
  methods: {
    changeValue(value) {
      this.$emit("input", value);
      this.active = false;
    },
  },
  computed: {
    current() {
      for (let i = 0; i < this.options.length; i++) {
        if (this.options[i].data === this.value) return this.options[i].text;
      }
      return this.placeholder;
    },
  },
};
</script>

<style scoped lang="scss">
#popup {
  position: absolute;
  width: 100%;
  height: 200px;
  top: 25px;
  left: 0;
  overflow-y: auto;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border: 1px solid rgba(66, 84, 102, 0.2);
  border-top: none;
  border-radius: 0 0 5px 5px;
  box-sizing: border-box;
  z-index: 60;
  .country {
    padding: 5px;
    width: 100%;
    box-sizing: border-box;
    text-align: left;
    display: block;
    margin: 0;
    &:hover {
      background-color: #888;
      cursor: pointer;
    }
  }
}
div {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin-bottom: 20px;

  #icon {
    width: 12px;
    position: absolute;
    left: 15px;
    top: 6px;
  }
  #edit {
    position: absolute;
    right: 15px;
    top: 12px;
    transition: transform 0.1s ease-in-out;
    &:hover {
      cursor: pointer;
    }
  }
  label {
    color: black;
    position: absolute;
    left: 35px;
    top: 5px;
    padding-left: 2px;
    padding-right: 2px;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    pointer-events: none;
  }
}
#value {
  box-sizing: border-box;
  border: 1px solid rgba(66, 84, 102, 0.2);
  border-radius: 5px;
  margin: 0;
  padding: 8px;
  padding-left: 35px;
  padding-top: 27px;
  padding-bottom: 16px;
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
  background: none;
  line-height: 1;

  text-align: left;
  /* Browsers have different default form fonts */
  font-family: "Montserrat", sans-serif;
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  height: 34px;
  border-radius: 5px;
  letter-spacing: 0.05em;
  &::placeholder {
    font-weight: normal;
  }
}
</style>
