<template>
  <div>
    <img :src="img" id="icon" />
    <label>{{ name }}</label>
    <input
      id="value"
      :name="name"
      :placeholder="placeholder"
      :tabindex="tabindex"
      :style="{ borderColor: required && !valid ? 'red' : '' }"
      :type="type"
      :value="value"
      @input="input"
      :readonly="!editable"
      ref="input"
    />
    <a v-if="type === 'download'" :href="downloadLink" download>
      <img id="edit" src="/img/icons/arrowDown.svg" />
    </a>
    <img v-if="icon" :src="icon" id="edit" />
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "AccountInput",
  props: {
    type: String,
    name: String,
    value: String,
    placeholder: String,
    tabindex: String,
    img: String,
    editable: Boolean,
    downloadLink: {
      require: false,
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    icon: {
      required: false,
      type: String,
      default: null,
    },
  },
  components: {},
  data() {
    return {
      editing: false,
      editValue: this.value,
    };
  },
  methods: {
    input() {
      this.$emit("input", this.$refs.input.value);
    },
    startEditing() {
      this.editing = true;
      if (Array.isArray(this.value)) {
        this.editValue = JSON.parse(JSON.stringify(this.value));
      } else this.editValue = this.value;
    },
    save() {
      this.editing = false;
      this.$emit("input", this.editValue);
    },
  },
  computed: {
    text() {
      if (this.type === "password") return "********";
      if (Array.isArray(this.value)) {
        let combinedValue = this.value[0].data;
        for (let i = 1; i < this.value.length; i++) {
          combinedValue += ` ${this.value[i].data}`;
        }
        return combinedValue;
      }
      return this.value === "" ? this.placeholder : this.value;
    },
    valid() {
      if (Array.isArray(this.value)) {
        let empty = false;
        for (let i = 0; i < this.value.length; i++) {
          if (this.value[i].data.length === 0) empty = true;
        }
        return !empty;
      } else {
        return this.value.length > 0;
      }
    },
  },
};
</script>

<style scoped lang="scss">
div {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin-bottom: 20px;

  #icon {
    width: 12px;
    position: absolute;
    left: 15px;
    top: 6px;
    filter: grayscale(100%);
  }
  #edit {
    position: absolute;
    right: 15px;
    top: 12px;
    height: 22px;
    &:hover {
      cursor: pointer;
    }
  }
  label {
    color: black;
    position: absolute;
    left: 35px;
    top: 5px;
    padding-left: 2px;
    padding-right: 2px;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    pointer-events: none;
  }
}
#value {
  box-sizing: border-box;
  border: 1px solid rgba(66, 84, 102, 0.2);
  border-radius: 5px;
  margin: 0;
  padding: 8px;
  padding-left: 35px;
  padding-top: 27px;
  padding-bottom: 16px;
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
  background: none;
  line-height: 1;

  text-align: left;
  /* Browsers have different default form fonts */
  font-family: "Montserrat", sans-serif;
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  height: 34px;
  border-radius: 5px;
  letter-spacing: 0.05em;
  &::placeholder {
    font-weight: normal;
  }
}

#popup-backgrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 49;
  background-color: rgba(0, 0, 0, 0.3);
}

#edit-popup {
  position: fixed;
  top: 20vh;
  left: 40vw;
  min-height: 20vh;
  max-height: 75vh;
  width: 20vw;
  background-color: white;
  border-radius: 5px;
  border: solid 1px black;
  z-index: 50;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  button {
    margin-left: auto;
    background-color: black;
    color: white;
    border: none;
    border-radius: 3px;
    margin-top: 10px;
    padding: 5px;
    padding-left: 20px;
    padding-right: 20px;
    &:focus,
    &:hover {
      outline: none;
      cursor: pointer;
      background-color: #ff752c;
    }
  }
  input {
    border: none;
    border-bottom: solid 1px black;
    transition: border-bottom 0.1s;
    font-size: 14px;
    font-weight: bold;
    width: 100%;
    margin-top: 20px;
    &:focus {
      outline: none;
      border-bottom: solid 1px #ff752c;
    }
    &:hover {
      border-bottom: solid 1px #ff752c;
    }
  }
  div {
    display: block;
    text-align: left;
    font-size: 10px;
  }
  img {
    position: absolute;
    top: 10px;
    left: 10px;
    height: 10px;
  }
}
</style>
