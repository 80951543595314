export const countries = [
  { data: "AD", text: "Andorra" },
  { data: "AE", text: "United Arab Emirates" },
  { data: "AF", text: "Afghanistan" },
  { data: "AG", text: "Antigua and Barbuda" },
  { data: "AI", text: "Anguilla" },
  { data: "AL", text: "Albania" },
  { data: "AM", text: "Armenia" },
  { data: "AO", text: "Angola" },
  { data: "AQ", text: "Antarctica" },
  { data: "AR", text: "Argentina" },
  { data: "AS", text: "American Samoa" },
  { data: "AT", text: "Austria" },
  { data: "AU", text: "Australia" },
  { data: "AW", text: "Aruba" },
  { data: "AX", text: "Åland Islands" },
  { data: "AZ", text: "Azerbaijan" },
  { data: "BA", text: "Bosnia and Herzegovina" },
  { data: "BB", text: "Barbados" },
  { data: "BD", text: "Bangladesh" },
  { data: "BE", text: "Belgium" },
  { data: "BF", text: "Burkina Faso" },
  { data: "BG", text: "Bulgaria" },
  { data: "BH", text: "Bahrain" },
  { data: "BI", text: "Burundi" },
  { data: "BJ", text: "Benin" },
  { data: "BL", text: "Saint Barthélemy" },
  { data: "BM", text: "Bermuda" },
  { data: "BN", text: "Brunei Darussalam" },
  { data: "BO", text: "Bolivia, Plurinational State of" },
  { data: "BQ", text: "Bonaire, Sint Eustatius and Saba" },
  { data: "BR", text: "Brazil" },
  { data: "BS", text: "Bahamas" },
  { data: "BT", text: "Bhutan" },
  { data: "BV", text: "Bouvet Island" },
  { data: "BW", text: "Botswana" },
  { data: "BY", text: "Belarus" },
  { data: "BZ", text: "Belize" },
  { data: "CA", text: "Canada" },
  { data: "CC", text: "Cocos (Keeling) Islands" },
  { data: "CD", text: "Congo, Democratic Republic of the" },
  { data: "CF", text: "Central African Republic" },
  { data: "CG", text: "Congo" },
  { data: "CH", text: "Switzerland" },
  { data: "CI", text: "Côte d'Ivoire" },
  { data: "CK", text: "Cook Islands" },
  { data: "CL", text: "Chile" },
  { data: "CM", text: "Cameroon" },
  { data: "CN", text: "China" },
  { data: "CO", text: "Colombia" },
  { data: "CR", text: "Costa Rica" },
  { data: "CU", text: "Cuba" },
  { data: "CV", text: "Cabo Verde" },
  { data: "CW", text: "Curaçao" },
  { data: "CX", text: "Christmas Island" },
  { data: "CY", text: "Cyprus" },
  { data: "CZ", text: "Czechia" },
  { data: "DE", text: "Germany" },
  { data: "DJ", text: "Djibouti" },
  { data: "DK", text: "Denmark" },
  { data: "DM", text: "Dominica" },
  { data: "DO", text: "Dominican Republic" },
  { data: "DZ", text: "Algeria" },
  { data: "EC", text: "Ecuador" },
  { data: "EE", text: "Estonia" },
  { data: "EG", text: "Egypt" },
  { data: "EH", text: "Western Sahara" },
  { data: "ER", text: "Eritrea" },
  { data: "ES", text: "Spain" },
  { data: "ET", text: "Ethiopia" },
  { data: "FI", text: "Finland" },
  { data: "FJ", text: "Fiji" },
  { data: "FK", text: "Falkland Islands (Malvinas)" },
  { data: "FM", text: "Micronesia, Federated States of" },
  { data: "FO", text: "Faroe Islands" },
  { data: "FR", text: "France" },
  { data: "GA", text: "Gabon" },
  { data: "GB", text: "United Kingdom" },
  { data: "GD", text: "Grenada" },
  { data: "GE", text: "Georgia" },
  { data: "GF", text: "French Guiana" },
  { data: "GG", text: "Guernsey" },
  { data: "GH", text: "Ghana" },
  { data: "GI", text: "Gibraltar" },
  { data: "GL", text: "Greenland" },
  { data: "GM", text: "Gambia" },
  { data: "GN", text: "Guinea" },
  { data: "GP", text: "Guadeloupe" },
  { data: "GQ", text: "Equatorial Guinea" },
  { data: "GR", text: "Greece" },
  { data: "GS", text: "South Georgia and the South Sandwich Islands" },
  { data: "GT", text: "Guatemala" },
  { data: "GU", text: "Guam" },
  { data: "GW", text: "Guinea-Bissau" },
  { data: "GY", text: "Guyana" },
  { data: "HK", text: "Hong Kong" },
  { data: "HM", text: "Heard Island and McDonald Islands" },
  { data: "HN", text: "Honduras" },
  { data: "HR", text: "Croatia" },
  { data: "HT", text: "Haiti" },
  { data: "HU", text: "Hungary" },
  { data: "ID", text: "Indonesia" },
  { data: "IE", text: "Ireland" },
  { data: "IL", text: "Israel" },
  { data: "IM", text: "Isle of Man" },
  { data: "IN", text: "India" },
  { data: "IO", text: "British Indian Ocean Territory" },
  { data: "IQ", text: "Iraq" },
  { data: "IR", text: "Iran, Islamic Republic of" },
  { data: "IS", text: "Iceland" },
  { data: "IT", text: "Italy" },
  { data: "JE", text: "Jersey" },
  { data: "JM", text: "Jamaica" },
  { data: "JO", text: "Jordan" },
  { data: "JP", text: "Japan" },
  { data: "KE", text: "Kenya" },
  { data: "KG", text: "Kyrgyzstan" },
  { data: "KH", text: "Cambodia" },
  { data: "KI", text: "Kiribati" },
  { data: "KM", text: "Comoros" },
  { data: "KN", text: "Saint Kitts and Nevis" },
  { data: "KP", text: "Korea, Democratic People's Republic of" },
  { data: "KR", text: "Korea, Republic of" },
  { data: "KW", text: "Kuwait" },
  { data: "KY", text: "Cayman Islands" },
  { data: "KZ", text: "Kazakhstan" },
  { data: "LA", text: "Lao People's Democratic Republic" },
  { data: "LB", text: "Lebanon" },
  { data: "LC", text: "Saint Lucia" },
  { data: "LI", text: "Liechtenstein" },
  { data: "LK", text: "Sri Lanka" },
  { data: "LR", text: "Liberia" },
  { data: "LS", text: "Lesotho" },
  { data: "LT", text: "Lithuania" },
  { data: "LU", text: "Luxembourg" },
  { data: "LV", text: "Latvia" },
  { data: "LY", text: "Libya" },
  { data: "MA", text: "Morocco" },
  { data: "MC", text: "Monaco" },
  { data: "MD", text: "Moldova, Republic of" },
  { data: "ME", text: "Montenegro" },
  { data: "MF", text: "Saint Martin, (French part)" },
  { data: "MG", text: "Madagascar" },
  { data: "MH", text: "Marshall Islands" },
  { data: "MK", text: "North Macedonia" },
  { data: "ML", text: "Mali" },
  { data: "MM", text: "Myanmar" },
  { data: "MN", text: "Mongolia" },
  { data: "MO", text: "Macao" },
  { data: "MP", text: "Northern Mariana Islands" },
  { data: "MQ", text: "Martinique" },
  { data: "MR", text: "Mauritania" },
  { data: "MS", text: "Montserrat" },
  { data: "MT", text: "Malta" },
  { data: "MU", text: "Mauritius" },
  { data: "MV", text: "Maldives" },
  { data: "MW", text: "Malawi" },
  { data: "MX", text: "Mexico" },
  { data: "MY", text: "Malaysia" },
  { data: "MZ", text: "Mozambique" },
  { data: "NA", text: "Namibia" },
  { data: "NC", text: "New Caledonia" },
  { data: "NE", text: "Niger" },
  { data: "NF", text: "Norfolk Island" },
  { data: "NG", text: "Nigeria" },
  { data: "NI", text: "Nicaragua" },
  { data: "NL", text: "Netherlands" },
  { data: "NO", text: "Norway" },
  { data: "NP", text: "Nepal" },
  { data: "NR", text: "Nauru" },
  { data: "NU", text: "Niue" },
  { data: "NZ", text: "New Zealand" },
  { data: "OM", text: "Oman" },
  { data: "PA", text: "Panama" },
  { data: "PE", text: "Peru" },
  { data: "PF", text: "French Polynesia" },
  { data: "PG", text: "Papua New Guinea" },
  { data: "PH", text: "Philippines" },
  { data: "PK", text: "Pakistan" },
  { data: "PL", text: "Poland" },
  { data: "PM", text: "Saint Pierre and Miquelon" },
  { data: "PN", text: "Pitcairn" },
  { data: "PR", text: "Puerto Rico" },
  { data: "PS", text: "Palestine, State of" },
  { data: "PT", text: "Portugal" },
  { data: "PW", text: "Palau" },
  { data: "PY", text: "Paraguay" },
  { data: "QA", text: "Qatar" },
  { data: "RE", text: "Réunion" },
  { data: "RO", text: "Romania" },
  { data: "RS", text: "Serbia" },
  { data: "RU", text: "Russian Federation" },
  { data: "RW", text: "Rwanda" },
  { data: "SA", text: "Saudi Arabia" },
  { data: "SB", text: "Solomon Islands" },
  { data: "SC", text: "Seychelles" },
  { data: "SD", text: "Sudan" },
  { data: "SE", text: "Sweden" },
  { data: "SG", text: "Singapore" },
  { data: "SH", text: "Saint Helena, Ascension and Tristan da Cunha" },
  { data: "SI", text: "Slovenia" },
  { data: "SJ", text: "Svalbard and Jan Mayen" },
  { data: "SK", text: "Slovakia" },
  { data: "SL", text: "Sierra Leone" },
  { data: "SM", text: "San Marino" },
  { data: "SN", text: "Senegal" },
  { data: "SO", text: "Somalia" },
  { data: "SR", text: "Suritext" },
  { data: "SS", text: "South Sudan" },
  { data: "ST", text: "Sao Tome and Principe" },
  { data: "SV", text: "El Salvador" },
  { data: "SX", text: "Sint Maarten, (Dutch part)" },
  { data: "SY", text: "Syrian Arab Republic" },
  { data: "SZ", text: "Eswatini" },
  { data: "TC", text: "Turks and Caicos Islands" },
  { data: "TD", text: "Chad" },
  { data: "TF", text: "French Southern Territories" },
  { data: "TG", text: "Togo" },
  { data: "TH", text: "Thailand" },
  { data: "TJ", text: "Tajikistan" },
  { data: "TK", text: "Tokelau" },
  { data: "TL", text: "Timor-Leste" },
  { data: "TM", text: "Turkmenistan" },
  { data: "TN", text: "Tunisia" },
  { data: "TO", text: "Tonga" },
  { data: "TR", text: "Turkey" },
  { data: "TT", text: "Trinidad and Tobago" },
  { data: "TV", text: "Tuvalu" },
  { data: "TW", text: "Taiwan" },
  { data: "TZ", text: "Tanzania, United Republic of" },
  { data: "UA", text: "Ukraine" },
  { data: "UG", text: "Uganda" },
  { data: "UM", text: "United States Minor Outlying Islands" },
  { data: "US", text: "United States of America" },
  { data: "UY", text: "Uruguay" },
  { data: "UZ", text: "Uzbekistan" },
  { data: "VA", text: "Holy See" },
  { data: "VC", text: "Saint Vincent and the Grenadines" },
  { data: "VE", text: "Venezuela, Bolivarian Republic of" },
  { data: "VG", text: "Virgin Islands, British" },
  { data: "VI", text: "Virgin Islands, U.S." },
  { data: "VN", text: "Viet Nam" },
  { data: "VU", text: "Vanuatu" },
  { data: "WF", text: "Wallis and Futuna" },
  { data: "WS", text: "Samoa" },
  { data: "XK", text: "Kosovo, Republic of" },
  { data: "YE", text: "Yemen" },
  { data: "YT", text: "Mayotte" },
  { data: "ZA", text: "South Africa" },
  { data: "ZM", text: "Zambia" },
  { data: "ZW", text: "Zimbabwe" },
];
