<template>
  <div>
    <div id="payment-dropin"></div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import braintree from "braintree-web-drop-in";
export default {
  components: {},
  props: { refcode: String },
  computed: {
    ...mapState({
      subscriptionPlan: (state) => state.userData.subscriptionPlan,
    }),
  },
  methods: {
    getPaymentToken() {
      return fetch(`${process.env.VUE_APP_BASE_URL}/payment/gettoken`, {
        credentials: "include",
      }).then((res) => res.json());
    },
    sendNonceToServer(nonce) {
      fetch(`${process.env.VUE_APP_BASE_URL}/payment/submitnonce`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          nonce: nonce,
          subscriptionPlanId: this.subscriptionPlan,
          referralCode: this.refcode,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            this.$emit("submit");
          } else {
            alert(res.msg);
            //todo redirect to failure-page
          }
        })
        .catch((err) => {
          alert(
            "Something went wrong... try again or contact our support at contact@merchcosmos.com"
          );
          //todo redirect to failure-page - "failed to submit payment method"
          throw err;
        });
    },
  },
  async mounted() {
    const submitButton = document.getElementById("submit");
    try {
      const data = await this.getPaymentToken();
      if (!data.success) throw new Error("failed to get payment token");

      braintree.create(
        {
          environment: "sandbox",
          authorization: data.token,
          container: "#payment-dropin",
          paypal: {
            flow: "vault",
          },
          vaultManager: true,
        },
        (createErr, instance) => {
          if (createErr) throw createErr;

          submitButton.addEventListener("click", () => {
            instance.requestPaymentMethod((err, payload) => {
              if (err) throw err;
              else this.sendNonceToServer(payload.nonce);
            });
          });

          if (instance.isPaymentMethodRequestable()) {
            submitButton.removeAttribute("disabled");
          }

          instance.on("paymentMethodRequestable", () => {
            submitButton.removeAttribute("disabled");
          });

          instance.on("noPaymentMethodRequestable", () => {
            //todo error?
            submitButton.setAttribute("disabled", true);
          });
        }
      );
    } catch (err) {
      //todo redirect to failure-page - "Failed to request payment method"
      // console.log(err);
    }
  },
};
</script>

<style scoped lang="scss">
#payment-dropin {
  min-height: 150px;
  width: 50%;
  margin: auto;
}
</style>
